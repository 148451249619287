import axios from "axios";
import {
  DELETE_INVOICE_PAYMENT,
  DELETE_INVOICE_PAYMENTS,
  GET_QUICKBOOKS_INVOICES,
  INVOICES_PAYMENTS_LOADING,
  POST_INVOICES_PAYMENTS
} from "../../redux/types.js";

export const getQuickBooksInvoices = () => dispatch => {
  dispatch(setInvoicesPaymentsLoading());

  const getQuickBooksInvoices = async () => {
    try {
      // GET QUICKBOOKS CLIENT ACCESS FROM LOCAL STORAGE
      const realmId = localStorage.responseURL
        .split("realmId=")
        .pop()
        .split('"')[0]
        .toString();
      let profile = await axios.get("/api/profile");
      let profileData = profile.data;
      let qbAuth = await localStorage.access_token;
      let toPost = { profileData, qbAuth, realmId };

      // SEND QUERY TO AZURE FUNCTION TO LOOK FOR PAYMENTS
      let response = await axios.post("/api/postFunctions/quickbooks", { action: GET_QUICKBOOKS_INVOICES, toPost: toPost });

      if (response.data === "None") {
        dispatch({
          type: GET_QUICKBOOKS_INVOICES,
          payload: "None"
        });
        return;
      } else {
        dispatch({
          type: GET_QUICKBOOKS_INVOICES,
          payload: response.data
        });
      }
    } catch (error) {
      console.log(error);
      dispatch({
        type: GET_QUICKBOOKS_INVOICES,
        payload: 500
      });
      return;
    }
  };
  getQuickBooksInvoices();
};

// DELETE SINGLE INVOICE PAYMENT FROM STATE
export const deleteQuickBooksInvoice = Id => dispatch => {
  dispatch({
    type: DELETE_INVOICE_PAYMENT,
    payload: Id
  });
};

// DELETE ALL INVOICE PAYMENTS FROM STATE
export const deleteQuickBooksInvoices = invoices => dispatch => {
  dispatch({
    type: DELETE_INVOICE_PAYMENTS,
    payload: invoices
  });
};

// UPDATE INVOICE PAYMENT INFORMATION IN WORKBOOK
export const postInvoices = invoices => dispatch => {
  dispatch({
    type: POST_INVOICES_PAYMENTS,
    payload: invoices
  });
};

// INVOICES LOADING STATE
export const setInvoicesPaymentsLoading = () => {
  return {
    type: INVOICES_PAYMENTS_LOADING
  };
};

