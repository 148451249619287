import jwt_decode from "jwt-decode";
import React, { Component } from "react";
import { Provider } from "react-redux";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import SideBar from "../src/components/layout/sidebar";
import { logoutUser, setCurrentUser } from "./actions/redux/authActions";
import { clearCurrentProfile } from "./actions/redux/profileActions";
import "./App.css";
import ForgotPassword from "./components/auth/ForgotPassword";
import Login from "./components/auth/Login";
import Register from "./components/auth/Register";
import ResetPassword from "./components/auth/ResetPassword";
import UpdatePassword from "./components/auth/UpdatePassword";
import PrivateRoute from "./components/common/PrivateRoute";
import AdminRoute from "./components/common/AdminRoute";
import CreditorInvoices from "./components/creditorInvoices/CreditorInvoices";
import Dashboard from "./components/dashboard/Dashboard";
import EditProfile from "./components/edit-profile/EditProfile";
import Expenses from "./components/expenses/Expenses";
import Invoices from "./components/invoices/Invoices";
import CreditNotes from "./components/creditNotes/CreditNotes";
import Footer from "./components/layout/Footer";
import Landing from "./components/layout/Landing";
import Navbar from "./components/layout/Navbar";
import NotFound from "./components/not-found/NotFound";
import InvoicePayments from "./components/payments/InvoicePayments";
import purchases from "./components/purchases/Purchases";
//import Contacts from "./components/contacts/Contacts";
import Help from "./components/help/help"
//import { ContactsProvider } from "./components/context/ContactsState";

import store from "./store";
import setAuthToken from "./utils/setAuthToken";

import Quickbooks from "./components/quickbooksOauth/quickbooks";
import QuickbooksCallback from "./components/quickbooksOauth/quickbooksCallback";


class App extends Component {
  render() {
    if (localStorage.jwtToken) {
      // Set auth token header auth
      setAuthToken(localStorage.jwtToken);
      // Decode token and get user info and exp
      const decoded = jwt_decode(localStorage.jwtToken);
      // Set user and isAuthenticated
      store.dispatch(setCurrentUser(decoded));

      // Check for expired token
      const currentTime = Date.now() / 1000;
      if (decoded.exp < currentTime) {
        // Logout user
        store.dispatch(logoutUser());
        // Clear current Profile
        store.dispatch(clearCurrentProfile());
        // Redirect to login
        window.location.href = "/login";
      }
    }
    return (
      <Provider store={store}>
        <Router>
          <div id="App" className="App">
            <SideBar outerContainerId={"App"} />
            <Navbar />
            <Route exact path="/" component={(props) => <Landing {...props} />} />
            <div className="container">
              <Route exact path="/login" component={(props) => <Login {...props} />} />
              <Route exact path="/forgotPassword" component={(props) => <ForgotPassword {...props} />} />
              <Route exact path="/reset" component={ResetPassword} />
              <Switch>
                <AdminRoute exact path="/register" component={Register} />
              </Switch>
              <Switch>
                <PrivateRoute exact path="/updatepassword" component={UpdatePassword} />
              </Switch>
              <Switch>
                <PrivateRoute exact path="/dashboard" component={Dashboard} />
              </Switch>
              <Switch>
                <PrivateRoute exact path="/invoices" component={Invoices} />
              </Switch>
              <Switch>
                <PrivateRoute exact path="/creditnotes" component={CreditNotes} />
              </Switch>
              <Switch>
                <PrivateRoute exact path="/quickbooks" component={Quickbooks} />
              </Switch>
              {/* <ContactsProvider>
                <Switch>
                  <PrivateRoute exact path="/contacts" component={Contacts} />
                </Switch>
              </ContactsProvider> */}
              <Switch>
                <PrivateRoute
                  exact
                  path="/quickbooksCallback"
                  component={QuickbooksCallback}
                />
              </Switch>
              <Switch>
                <PrivateRoute exact path="/purchases" component={purchases} />
              </Switch>
              <Switch>
                <PrivateRoute
                  exact
                  path="/creditorinvoices"
                  component={CreditorInvoices}
                />
              </Switch>
              <Switch>
                <PrivateRoute exact path="/expenses" component={Expenses} />
              </Switch>
              <Switch>
                <PrivateRoute
                  exact
                  path="/invoicepayments"
                  component={InvoicePayments}
                />
              </Switch>
              <Switch>
                <PrivateRoute
                  exact
                  path="/edit-profile"
                  component={EditProfile}
                />
              </Switch>
              <Switch>
                <PrivateRoute exact path="/help" component={Help} />
              </Switch>
              <Route exact path="/not-found" component={NotFound} />
            </div>
            <Footer />
          </div>
        </Router>
      </Provider>
    );
  }
}

export default App;
