import axios from "axios";

const postWorkbookPayments = async function onPostClickWorkbook(event, invoices) {
  try {
    const invoicesToPostToWorkbook = invoices.map(ids => ids.Id);
    const toPost = { invoicesToPostToWorkbook };
    const response = await axios.post("/api/workbook/invpayments", toPost);
    return response;
  } catch (e) {
    console.log(e);
  }
};

export default postWorkbookPayments;
