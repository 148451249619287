import { combineReducers } from "redux";
import authReducer from "./authReducer";
import creditorInvoicesReducer from "./creditorInvoicesReducer";
import errorReducer from "./errorReducer";
import expensesReducer from "./expensesReducer";
import invoicePaymentsReducer from "./invoicePaymentsReducer";
import invoicesReducer from "./invoicesReducer";
import creditNotesReducer from "./creditNotesReducer";
import profileReducer from "./profileReducer";
import purchasesReducer from "./purchasesReducer";

export default combineReducers({
  auth: authReducer,
  errors: errorReducer,
  profile: profileReducer,
  invoices: invoicesReducer,
  creditNotes: creditNotesReducer,
  invoicePayments: invoicePaymentsReducer,
  purchases: purchasesReducer,
  creditorInvoices: creditorInvoicesReducer,
  expenses: expensesReducer,
});
