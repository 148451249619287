import axios from "axios";
import {
  DELETE_PURCHASE,
  DELETE_PURCHASES,
  GET_PURCHASES,
  POST_PURCHASES,
  PURCHASES_LOADING,
  UPDATE_PURCHASE_SINGLE_PAYMENT,
  UPDATE_PURCHASE_PAYMENTS,
} from "./types";

export const getPurchases = (accPayable) => (dispatch) => {
  dispatch(setPurchasesLoading());
  const getPurchases = async () => {
    try {
      let response = await axios.post("/api/postFunctions/workbook", { action: GET_PURCHASES });

      if (response.data === "none") {
        dispatch({
          type: GET_PURCHASES,
          payload: [],
        });
      } else {
        dispatch({
          type: GET_PURCHASES,
          payload: response.data,
        });
        dispatch({
          type: UPDATE_PURCHASE_PAYMENTS,
          acc_Payable: accPayable,
        });
      }
    } catch (e) {
      dispatch({
        type: GET_PURCHASES,
        payload: 9,
      });
    }
  };
  getPurchases();
};


// Delete Purchase
export const deletePurchase = (Id) => (dispatch) => {
  dispatch({
    type: DELETE_PURCHASE,
    payload: Id,
  });
};

// Delete Purchases
export const deletePurchases = (purchases) => (dispatch) => {
  dispatch({
    type: DELETE_PURCHASES,
    payload: purchases,
  });
};

// Post Purchases
export const postPurchases = (purchases) => (dispatch) => {
  dispatch({
    type: POST_PURCHASES,
    payload: purchases,
  });
};

// Purchases loading
export const setPurchasesLoading = () => {
  return {
    type: PURCHASES_LOADING,
  };
};

export const updateSinglePayment = (Id, accPayable) => (dispatch) => {
  dispatch({
    type: UPDATE_PURCHASE_SINGLE_PAYMENT,
    payload: Id,
    acc_Payable: accPayable,
  });
};

export const updatePayments = (accPayable) => (dispatch) => {
  dispatch({
    type: UPDATE_PURCHASE_PAYMENTS,
    acc_Payable: accPayable,
  });
};

