import PropTypes from "prop-types";
import React, { Fragment } from "react";

const QuickBooksButton = ({ onPostClickQuickBooks, buttonClass, title }) => (
  <Fragment>
    <button
      onClick={onPostClickQuickBooks}
      className={buttonClass}
      data-toggle="tooltip"
      data-html="true"
      title={title}
    >
      {" "}
      Export to QuickBooks
    </button>
  </Fragment>
);

QuickBooksButton.propTypes = {
  onPostClickQuickBooks: PropTypes.func,
  buttonClass: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
};

export default QuickBooksButton;