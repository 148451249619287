// ERRORS
export const GET_ERRORS = "GET_ERRORS";
export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const SET_CURRENT_USER = "SET_CURRENT_USER";

// PROFILES
export const GET_PROFILE = "GET_PROFILE";
export const PROFILE_LOADING = "PROFILE_LOADING";
export const PROFILE_NOT_FOUND = "PROFILE_NOT_FOUND";
export const CLEAR_CURRENT_PROFILE = "CLEAR_CURRENT_PROFILE";
export const GET_PROFILES = "GET_PROFILES";

// ACCOUNTS AND VENDORS
export const GET_ACCOUNTS = "GET_ACCOUNTS";
export const GET_VENDOR_CLIENTS = "GET_VENDOR_CLIENTS";

// SALES INVOICES
export const GET_INVOICES = "GET_INVOICES";
export const GET_INVOICE = "GET_INVOICE";
export const DELETE_INVOICE = "DELETE_INVOICE";
export const DELETE_INVOICES = "DELETE_INVOICES";
export const INVOICES_LOADING = "INVOICES_LOADING";
export const POST_INVOICES = "POST_INVOICES";
export const EXPORT_INVOICES = "EXPORT_INVOICES";

// CREDITOR INVOICES
export const GET_CREDITOR_INVOICES = "GET_CREDITOR_INVOICES";
export const GET_CREDITOR_INVOICE = "GET_CREDITOR_INVOICE";
export const DELETE_CREDITOR_INVOICE = "DELETE_CREDITOR_INVOICE";
export const DELETE_CREDITOR_INVOICES = "DELETE_CREDITOR_INVOICES";
export const CREDITOR_INVOICES_LOADING = "CREDITOR_INVOICES_LOADING";
export const POST_CREDITOR_INVOICES = "POST_CREDITOR_INVOICES";
export const EXPORT_CREDITOR_INVOICES = "EXPORT_CREDITOR_INVOICES";

// PURCHASES
export const GET_PURCHASES = "GET_PURCHASES";
export const GET_PURCHASE = "GET_PURCHASE";
export const DELETE_PURCHASE = "DELETE_PURCHASE";
export const DELETE_PURCHASES = "DELETE_PURCHASES";
export const PURCHASES_LOADING = "PURCHASES_LOADING";
export const POST_PURCHASES = "POST_PURCHASES";
export const UPDATE_PURCHASES_STATUS = "UPDATE_PURCHASES_STATUS";
export const UPDATE_PURCHASE_SINGLE_STATUS = "UPDATE_PURCHASE_SINGLE_STATUS";
export const UPDATE_PURCHASE_SINGLE_PAYMENT = "UPDATE_PURCHASE_SINGLE_PAYMENT";
export const UPDATE_PURCHASE_PAYMENTS = "UPDATE_PURCHASE_PAYMENTS";
export const EXPORT_PURCHASES = "EXPORT_PURCHASES";

// EXPENSES
export const GET_EXPENSES = "GET_EXPENSES";
export const GET_EXPENSE = "GET_EXPENSE";
export const DELETE_EXPENSE = "DELETE_EXPENSE";
export const DELETE_EXPENSES = "DELETE_EXPENSES";
export const EXPENSES_LOADING = "EXPENSES_LOADING";
export const POST_EXPENSES = "POST_EXPENSES";
export const UPDATE_EXPENSES_STATUS = "UPDATE_EXPENSES_STATUS";
export const UPDATE_EXPENSE_SINGLE_STATUS = "UPDATE_EXPENSE_SINGLE_STATUS";
export const UPDATE_EXPENSE_SINGLE_PAYMENT = "UPDATE_EXPENSE_SINGLE_PAYMENT";
export const UPDATE_EXPENSE_PAYMENTS = "UPDATE_EXPENSE_PAYMENTS";
export const EXPORT_EXPENSES = "EXPORT_EXPENSES";

// INVOICE PAYMENTS
export const GET_QUICKBOOKS_INVOICES = "GET_QUICKBOOKS_INVOICES";
export const INVOICES_PAYMENTS_LOADING = "INVOICES_PAYMENTS_LOADING";
export const DELETE_INVOICE_PAYMENT = " DELETE_INVOICE_PAYMENT ";
export const DELETE_INVOICE_PAYMENTS = "DELETE_INVOICE_PAYMENTS";
export const POST_INVOICES_PAYMENTS = "POST_INVOICES_PAYMENTS";

// CREDIT NOTES
export const GET_CREDIT_NOTES = "GET_CREDIT_NOTES";
export const GET_CREDIT_NOTE = "GET_CREDIT_NOTE";
export const DELETE_CREDIT_NOTES = "DELETE_CREDIT_NOTES";
export const DELETE_CREDIT_NOTE = "DELETE_CREDIT_NOTE";
export const CREDIT_NOTES_LOADING = "CREDIT_NOTES_LOADING";
export const POST_CREDIT_NOTES = "POST_CREDIT_NOTES";
export const EXPORT_CREDIT_NOTES = "EXPORT_CREDIT_NOTES";