import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Link } from 'react-router-dom';
import { deleteAccount, getCurrentProfile } from '../../actions/redux/profileActions';
import Spinner from '../common/Spinner';
import releaseNotesPDF from '../../pdfs/WorkBook-QuickBooks Connector release notes.pdf';

class Dashboard extends Component {
  componentDidMount() {
    this.forceUpdate();
    this.props.getCurrentProfile();
  }

  onDeleteClick(e) {
    this.props.deleteAccount();
  }

  render() {
    const { user } = this.props.auth;
    const { profile, loading } = this.props.profile;

    let dashboardContent;

    if (profile === null || loading) {
      dashboardContent = (
        <div style={{ marginTop: '200px' }}>
          <h3>Loading Profile ...</h3>
          <Spinner />
        </div>
      );
    } else {
      // Check if logged in user has profile data
      if (Object.keys(profile).length > 0) {
        dashboardContent = (
          <Router>
            <div>
              <h1
                style={{
                  color: '#5c5969',
                  paddingTop: '5em',
                  paddingBottom: '.5em'
                }}
              >
                Welcome, {profile.handle}!
              </h1>
              <h3 className="subHeading"><u>Release Notes</u></h3>
              <div>
              <a
                className="infoLink"
                href={releaseNotesPDF}
                target="_blank"
                rel="noreferrer"
              >
                WorkBook QuickBooks Connector {process.env.REACT_APP_VERSION ?? "DEV"}
              </a>
              <p style={{ paddingTop: "20px" }}>
                If you have any questions, please contact Smartsoftware support at
                <a style={{ color: '#4885ED' }} href="mailto: support@smartsoftware.net"> support@smartsoftware.net</a>.
              </p>
              </div>
            </div>
          </Router>
        );
      } else {
        // User is logged in but has no profile
        dashboardContent = (
          <div>
            <p className="lead text-muted">Welcome {user.name}</p>
            <p style={{ paddingBottom: '2em' }}>
              You have not yet setup a profile, please add some info
            </p>
            <Link to="/edit-profile" className="btn btn-md btn-primary">
              Edit Settings
            </Link>
          </div>
        );
      }
    }

    return (
      <div className="dashboard">
        <div className="container">
          <div className="row">
            <div className="col-md-12">{dashboardContent}</div>
          </div>
        </div>
      </div>
    );
  }
}

Dashboard.propTypes = {
  getCurrentProfile: PropTypes.func.isRequired,
  deleteAccount: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  profile: state.profile,
  auth: state.auth
});

export default connect(
  mapStateToProps,
  { getCurrentProfile, deleteAccount }
)(Dashboard);
