import axios from "axios";

const postWorkbook = async function onPostClickWorkbook(event, purchases) {
  try {
    const purchasesToPostToWorkbook = purchases.map(ids => ids.Id);
    const toPost = { purchasesToPostToWorkbook };
    const response = await axios.post("/api/workbook/purchases", toPost);
    return response;
  } catch (e) {
    console.log(e);
  }
};

export default postWorkbook;
