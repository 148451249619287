import axios from "axios";
import {
  CREDITOR_INVOICES_LOADING,
  DELETE_CREDITOR_INVOICE,
  DELETE_CREDITOR_INVOICES,
  GET_CREDITOR_INVOICES,
  POST_CREDITOR_INVOICES,
} from "./types";

// Get ALL Invoices
export const getCreditorInvoices = () => (dispatch) => {
  dispatch(setCreditorInvoicesLoading());

  const getCreditorInvoices = async () => {

    let response = await axios.post("/api/postFunctions/workbook", { action: GET_CREDITOR_INVOICES });

    if (response.data === 'none') {
      dispatch({
        type: GET_CREDITOR_INVOICES,
        payload: []
      });
    } else {
      dispatch({
        type: GET_CREDITOR_INVOICES,
        payload: response.data
      });
    }
  };
  getCreditorInvoices();
};

// Delete CreditorInvoice
export const deleteCreditorInvoice = (Id) => (dispatch) => {
  dispatch({
    type: DELETE_CREDITOR_INVOICE,
    payload: Id,
  });
};

// Delete CreditoInvoices
export const deleteCreditorInvoices = (creditorInvoices) => (dispatch) => {
  dispatch({
    type: DELETE_CREDITOR_INVOICES,
    payload: creditorInvoices,
  });
};

// Post CreditoInvoices
export const postCreditorInvoices = (creditorInvoices) => (dispatch) => {
  dispatch({
    type: POST_CREDITOR_INVOICES,
    payload: creditorInvoices,
  });
};

// CreditoInvoices loading
export const setCreditorInvoicesLoading = () => {
  return {
    type: CREDITOR_INVOICES_LOADING,
  };
};
