import React, { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf/dist/esm/entry.webpack';
import userGuidePDF from '../../pdfs/QB Smart Connector User Guide v3.pdf';
import Spinner from '../common/Spinner';
pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function Help() {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const loadingDoc = (
    <div className="loadingCanvas">
      <h3>Loading document...</h3>
      <Spinner />
    </div>
  );

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  function changePage(offset) {
    setPageNumber(prevPageNumber => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  return (
    <div className="centreDisplay">
      <h1>Help and Information</h1>
      <p style={{ padding: "10px 0px" }}>If you have any questions, please contact Smartsoftware support at
        <a style={{ color: '#4885ED' }} href="mailto: support@smartsoftware.net"> support@smartsoftware.net</a>.
      </p>
      <a
        className="infoLink"
        href={userGuidePDF}
        style={{ paddingBottom: "60px" }}
        target="_blank"
        rel="noreferrer"
      >
        PDF version
      </a>
      <div className="pdfNavbar">
        <button
          className="btn btn-primary pdfBtn"
          type="button"
          disabled={pageNumber <= 1}
          onClick={previousPage}
        >
          Previous
        </button>
        <p className="pdfPages">Page {pageNumber || (numPages ? 1 : '--')} of {numPages || '--'}</p>
        <button
          className="btn btn-primary pdfBtn"
          type="button"
          disabled={pageNumber >= numPages}
          onClick={nextPage}
        >
          Next
        </button>
      </div>
      <Document
        file={userGuidePDF}
        loading={loadingDoc}
        onLoadSuccess={onDocumentLoadSuccess}
        onLoadError={error => console.log(error)}
      >
        <Page
          pageNumber={pageNumber}
          className="centreBlock"
          loading={loadingDoc}
          width={800}
          renderTextLayer={false}
          renderAnnotationLayer={false}
        />
      </Document >
      <div className="pdfNavbar" style={{ paddingBottom: "60px" }}>
        <button
          className="btn btn-primary pdfBtn"
          type="button"
          disabled={pageNumber <= 1}
          onClick={previousPage}
        >
          Previous
        </button>
        <p className="pdfPages">Page {pageNumber || (numPages ? 1 : '--')} of {numPages || '--'}</p>
        <button
          className="btn btn-primary pdfBtn"
          type="button"
          disabled={pageNumber >= numPages}
          onClick={nextPage}
        >
          Next
        </button>
      </div>
    </div>
  );
}

export default Help;