import React, { Fragment } from "react";

function Found() {
  return <Fragment>&nbsp;&nbsp;Credit notes ready for export</Fragment>;
}

function Looking() {
  return (
    <Fragment>
      <h4>Looking for credit notes ...</h4>
    </Fragment>
  );
}

function ConErr() {
  return (
    <Fragment>
      <h1 style={{ color: "red", textAlign: "center" }}>Error</h1>
      <p style={{ PaddingTop: 3 + "em", textAlign: "center" }}>
        WorkBook connection error. Please review your connection settings and
        reload.
      </p>
    </Fragment>
  );
}

function FinalizedWorkbook() {
  return (
    <Fragment>
      <h4 style={{ PaddingTop: 3 + "em", textAlign: "center" }}>
        Credit notes finalized in WorkBook. Refresh this page to reload.
      </h4>
    </Fragment>
  );
}

function NoneFound() {
  return (
    <Fragment>
      <h4 style={{ color: "#4885ED" }}>No credit notes to export</h4>
      <p>Please review your credit note list in WorkBook and reload</p>
    </Fragment>
  );
}

function NoErrQuickBooks() {
  return (
    <h4 style={{ PaddingTop: 3 + "em", textAlign: "center" }}>No Errors!</h4>
  );
}

function ExportingQuickBooks() {
  return <h4 style={{ color: "#4885ED" }}>Exporting to QuickBooks</h4>;
}

function PostingWorkbook() {
  return <h4 style={{ color: "#4885ED" }}>Posting to WorkBook</h4>;
}

function AllPosted() {
  return (
    <Fragment>
      <h4 style={{ color: "#4885ED" }}>Everything is posted!</h4>
      <p>Please reload the page to export more credit notes</p>
    </Fragment>
  );
}

function QuickBooksNoErrors() {
  return (
    <Fragment>
      <h4 style={{ color: "#4885ED" }}>Everything has Exported!</h4>
      <p>
        There were no errors or warnings, please feel free to update credit
        notes in WorkBook
      </p>
    </Fragment>
  );
}

function IssuesFound() {
  return (
    <Fragment>
      <h4 style={{ color: "#d9534f" }}>Issues found ...</h4>
      <p>
        Please review the items below. You may wish to remove items in QuickBooks and
        then reload this page to begin again...
      </p>
    </Fragment>
  );
}

function QuickBooks500() {
  return (
    <Fragment>
      <h4 style={{ color: "#d9534f" }}>QuickBooks connection issue ...</h4>
      <p>QuickBooks returned a connection error, please review your settings...</p>
    </Fragment>
  );
}

export {
  Found,
  Looking,
  ConErr,
  FinalizedWorkbook,
  NoErrQuickBooks,
  NoneFound,
  ExportingQuickBooks,
  PostingWorkbook,
  AllPosted,
  QuickBooksNoErrors,
  IssuesFound,
  QuickBooks500
};
