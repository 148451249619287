import {
  DELETE_INVOICE_PAYMENT,
  DELETE_INVOICE_PAYMENTS,
  GET_QUICKBOOKS_INVOICES,
  INVOICES_PAYMENTS_LOADING,
  POST_INVOICES_PAYMENTS
} from "../types";

const initialState = {
  invoicePayments: [],
  loading: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case INVOICES_PAYMENTS_LOADING:
      return {
        ...state,
        loading: true
      };
    case GET_QUICKBOOKS_INVOICES:
      return {
        ...state,
        invoicePayments: action.payload,
        loading: false
      };
    case DELETE_INVOICE_PAYMENT:
      return Object.assign({}, state, {
        invoicePayments: [
          ...state.invoicePayments.filter(
            invoicePayment => invoicePayment.Id !== action.payload
          )
        ]
      });
    case DELETE_INVOICE_PAYMENTS:
      return { invoicePayments: [] };
    case POST_INVOICES_PAYMENTS:
      break;
    default:
      return state;
  }
}
