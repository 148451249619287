import format from "date-fns/format";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Button, Collapse } from "react-bootstrap";
import { connect } from "react-redux";
import styled from "styled-components";
import {
  deletePurchase,
  updateSinglePayment,
} from "../../actions/redux/purchaseActions";

class PurchaseItem extends Component {
  state = {
    showPurchaseInfo: false,
    isDraftChecked: "checked",
    isAuthorisedChecked: "",
    accountPayable: JSON.parse(localStorage.getItem("q_acc")) ?
      JSON.parse(localStorage.getItem("q_acc"))[0] :
      { acc_Id: "0", acc_AcctNum: "0", acc_Name: "None Found" },
  };

  onDeleteClick(e) {
    this.props.deletePurchase(this.props.purchase.Id);
  }

  componentWillReceiveProps() {
    this.setState({
      accountPayable: {
        acc_Id: this.props.purchase.AccountAP.AccountCode,
        acc_AcctNum: this.props.purchase.AccountAP.AcctNum,
        acc_Name: this.props.purchase.AccountAP.DisplayName,
      },
    });
  }

  handleSelectValue = (event) => {
    const account = JSON.parse(event.target.value)
    this.props.updateSinglePayment(this.props.purchase.Id, account);
    this.setState({ accountPayable: account });
  };

  render() {
    const { purchase } = this.props;
    const { showPurchaseInfo, accountPayable } = this.state;

    // GET ACCOUNTS PAYABLE OPTIONS FROM LOCAL STORAGE. IF IT DOES NOT EXIST OR IS EMPTY, CREATE A BLANK ONE TO AVOID MAPPING ERRORS.
    let accountsPayableOptions = [accountPayable];
    if (
      localStorage.getItem("q_acc") !== undefined &&
      localStorage.getItem("q_acc") !== null &&
      localStorage.getItem("q_acc").length !== 0
    ) {
      accountsPayableOptions = JSON.parse(localStorage.getItem("q_acc"));
    }

    return (
      <Wrapper>
        <div className="card card-header bg-light">
          <div>
            <div className="radio-status item-radio"></div>
            <div className="radio-status item-radio">
              <select
                value={JSON.stringify(accountPayable)}
                onChange={this.handleSelectValue}
                className="form-control"
                id="paymentMethod"
              >
                {accountsPayableOptions.map((account) => {
                  return <option value={JSON.stringify(account)} key={account.acc_Id}>{account.acc_Name}</option>;
                })}
              </select>
            </div>
            <div
              className="item-delete"
              style={{
                textAlign: "right",
                color: "red",
                cursor: "pointer",
              }}
            >
              <i
                className="fa fa-times"
                aria-hidden="true"
                onClick={this.onDeleteClick.bind(this)}
              />
            </div>
          </div>

          <ul className="list-group-items list-group  list-group-flush">
            <div className="table-responsive">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th style={{ width: "10%" }} scope="col">
                      PO#
                    </th>
                    <th style={{ width: "10%" }} scope="col">
                      Supplier
                    </th>
                    <th style={{ width: "15%" }} scope="col">
                      Issued
                    </th>
                    <th style={{ width: "15%" }} scope="col">
                      Delivery Date
                    </th>
                    <th style={{ width: "15%" }} scope="col">
                      Total
                    </th>
                    <th style={{ width: "45%" }} scope="col">
                      Job
                    </th>
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    <td>{purchase.PurchaseOrderNumber}</td>
                    <td>{purchase.Contact.Name}</td>
                    <td>{format(new Date(purchase.Date), "DD/MM/YYYY")}</td>
                    <td>
                      {format(new Date(purchase.DeliveryDate), "DD/MM/YYYY")}
                    </td>
                    <td>${purchase.Total}</td>
                    <td>{purchase.Reference}</td>
                  </tr>
                </tbody>
              </table>
              <Button
                className="lineItemsButton"
                onClick={() =>
                  this.setState({ showPurchaseInfo: !showPurchaseInfo })
                }
                variant="primary"
                size="sm"
                aria-controls="line-items"
                aria-expanded={showPurchaseInfo}
                style={{ marginBottom: "1em", marginTop: "1em" }}
              >
                Line Items
              </Button>
              <Collapse in={this.state.showPurchaseInfo}>
                <div id="line-items">
                  <ul className="list-group">
                    <table className="table table-striped">
                      <thead className="thead-dark">
                        <tr>
                          <th style={{ width: "30%" }} scope="col">
                            Description
                          </th>
                          <th scope="col">Qty</th>
                          <th scope="col">Unit $</th>
                          <th scope="col">Acc code</th>
                          <th scope="col">Activity name</th>
                        </tr>
                      </thead>
                      <tbody>
                        {purchase.LineItems.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td>{item.Description}</td>
                              <td>{item.Quantity}</td>
                              <td>${item.UnitAmount}</td>
                              <td>{item.AccountCode}</td>
                              <td>{item.ActivityName}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </ul>
                </div>
              </Collapse>
            </div>
          </ul>
        </div>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  width: auto;
  padding: 5px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 2px 6px 2px;
  textshadow: 0px 5px 15px rgba(255, 255, 255, 0.5);
  margin-bottom: 1em;
`;

PurchaseItem.propTypes = {
  purchase: PropTypes.object.isRequired,
  deletePurchase: PropTypes.func,
  updateSinglePayment: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => {
  return {
    deletePurchase: (purchase) => dispatch(deletePurchase(purchase)),
    updateSinglePayment: (purchase) => dispatch(updateSinglePayment(purchase)),
  };
};

export default connect(mapDispatchToProps, {
  deletePurchase,
  updateSinglePayment,
})(PurchaseItem);
