import TextField from "@material-ui/core/TextField";
import axios from "axios";
import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import Spinner from '../common/Spinner';
import {
  inputStyle,
  LinkButtons,
  SubmitButtons
} from "./authComponents";

const buttonStyle = {
  marginTop: "1.5em",
  backgroundColor: "#4885ED"
}

class UpdatePassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      oldPassword: "",
      password: "",
      confirmPassword: "",
      loadingUser: false,
      updated: false,
      error: ""
    };
  }

  componentDidMount() {
    this.setState({ loadingUser: true });

    const accessString = localStorage.getItem("jwtToken");

    if (accessString === null) {
      this.setState({
        loadingUser: false,
        error: "Unauthorised access"
      });
    } else {
      axios
        .get("/api/users/current", {
          headers: { Authorization: accessString }
        })
        .then(response => {
          this.setState({
            loadingUser: false,
            email: response.data.email,
            error: ""
          });
        })
        .catch(error => {
          this.setState({
            loadingUser: false,
            error: "Unauthorised access"
          });
        });
    }
  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };

  updatePassword = e => {
    e.preventDefault();
    const accessString = localStorage.getItem("jwtToken");
    if (accessString === null) {
      this.setState({
        loadingUser: false,
        error: "Unauthorised access",
        updated: false
      });
    }
    else if (this.state.password.trim() === "" || this.state.password.length < 6) {
      this.setState({
        loadingUser: false,
        error: "New password cannot be empty and must be at least 6 characters",
        updated: false
      })
    }
    else if (this.state.password !== this.state.confirmPassword) {
      this.setState({
        loadingUser: false,
        error: "New password and confirm password do not match",
        updated: false
      });
    }
    else if (this.state.password === this.state.oldPassword) {
      this.setState({
        loadingUser: false,
        error: "New password and old password cannot match",
        updated: false
      });
    }
    else {
      axios.post(
        "api/users/checkPassword",
        {
          email: this.state.email,
          password: this.state.oldPassword
        },
        {
          headers: { Authorization: accessString }
        }
      )
        .then(response => {
          if (response.data.message === "Match!") {
            axios
              .put(
                "api/passwords/updatePassword",
                {
                  email: this.state.email,
                  password: this.state.password
                },
                {
                  headers: { Authorization: accessString }
                }
              )
              .then(response => {
                if (response.data.message === "password updated") {
                  this.setState({
                    updated: true,
                    error: "",
                    oldPassword: "",
                    password: "",
                    confirmPassword: "",
                    loadingUser: false
                  });
                }
              })
              .catch(error => {
                this.setState({
                  updated: false,
                  error: error.response.data,
                  loadingUser: false
                });
              });
          }
        })
        .catch(error => {
          this.setState({
            updated: false,
            error: error.response.data,
            loadingUser: false
          });
        });
    }
  };

  render() {
    const { oldPassword, password, confirmPassword, updated, error, loadingUser } = this.state;

    if (loadingUser !== false) {
      return (
        <div className="centreDisplay">
          <h3>Loading user data...</h3>
          <Spinner />
        </div>
      );
    }
    if (loadingUser === false && error === "Unauthorised access") {
      return <Redirect to={`/login`} />;
    }
    if (loadingUser === false && updated === true) {
      return (
        <div className="centreDisplay">
          <h4 className="confirmStyle">Your password has been successfully updated.</h4>
          <LinkButtons
            buttonStyle={buttonStyle}
            buttonText="Go back Home"
            link={`/dashboard`}
          />
        </div>
      );
    }
    if (loadingUser === false) {
      return (
        <div className="centreDisplay">
          <h4>Update your password</h4>
          <form className="profile-form" onSubmit={this.updatePassword} style={{ marginTop: "1em" }}>
            <div className="form-group">
              <TextField
                style={inputStyle}
                id="oldPassword"
                label="Old password"
                value={oldPassword}
                onChange={this.handleChange("oldPassword")}
                type="password"
              />
            </div>
            <div className="form-group">
              <TextField
                style={inputStyle}
                id="password"
                label="New password"
                value={password}
                onChange={this.handleChange("password")}
                type="password"
              />
            </div>
            <div className="form-group">
              <TextField
                style={inputStyle}
                id="confirmPassword"
                label="Confirm password"
                value={confirmPassword}
                onChange={this.handleChange("confirmPassword")}
                type="password"
              />
            </div>
            {error !== "" && <p className="errorStyle">Error: {error}.</p>}
            <SubmitButtons buttonStyle={buttonStyle} buttonText="Update Password" />
          </form>

          <LinkButtons
            buttonStyle={buttonStyle}
            buttonText="Go back Home"
            link={`/dashboard`}
          />
        </div>
      );
    }
  }
}



export default UpdatePassword;
