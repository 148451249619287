import format from "date-fns/format";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Button, Collapse } from "react-bootstrap";
import { connect } from "react-redux";
import styled from "styled-components";
import {
  deleteExpense,
  updateSingleStatus,
  updateSinglePayment,
} from "../../actions/redux/expenseActions";

class ExpenseItem extends Component {
  state = {
    showExpenseInfo: false,
    isDraftChecked: "checked",
    isAuthorisedChecked: "",
    paymentMethod: JSON.parse(localStorage.getItem("q_exp")) ?
      JSON.parse(localStorage.getItem("q_exp"))[0] :
      { acc_Id: "0", acc_Name: "None Found", acc_Type: "Nothing" },
  };

  onDeleteClick(e) {
    this.props.deleteExpense(this.props.expense.Id);
  }

  handleSelectValue = (event) => {
    console.log(event.target.value);
    const paymentOption = JSON.parse(event.target.value)
    this.props.updateSinglePayment(this.props.expense.Id, paymentOption);
    this.setState({ paymentMethod: paymentOption });
  };

  componentWillReceiveProps() {
    this.setState({
      paymentMethod: {
        acc_Id: this.props.expense.AccountRef.AccountCode,
        acc_AcctNum: this.props.expense.AccountRef.AccountNum,
        acc_Name: this.props.expense.AccountRef.AccountName,
        acc_Type: this.props.expense.PayMethod,
      },
    });
  }

  render() {
    const { expense } = this.props;
    const { showExpenseInfo, paymentMethod } = this.state;

    // GET EXPENSE OPTIONS FROM LOCAL STORAGE. IF IT DOES NOT EXIST OR IS EMPTY, CREATE A BLANK ONE TO AVOID MAPPING ERRORS.
    let expensePaymentOptions = [paymentMethod];
    if (
      localStorage.getItem("q_exp") !== undefined &&
      localStorage.getItem("q_exp") !== null &&
      localStorage.getItem("q_exp").length !== 0
    ) {
      expensePaymentOptions = JSON.parse(localStorage.getItem("q_exp"));
    }

    return (
      <Wrapper>
        <div className="card card-header bg-light">
          <div>
            <div className="radio-status item-radio"></div>
            <div className="radio-status item-radio">
              <select
                value={JSON.stringify(paymentMethod)}
                onChange={this.handleSelectValue}
                className="form-control"
                id="paymentMethod"
              >
                {expensePaymentOptions.map((option) => {
                  return <option key={option.acc_Id} value={JSON.stringify(option)}>{option.acc_Name}</option>;
                })}
              </select>
            </div>

            <div
              className="item-delete"
              style={{
                textAlign: "right",
                color: "red",
                cursor: "pointer",
              }}
            >
              <i
                className="fa fa-times"
                aria-hidden="true"
                onClick={this.onDeleteClick.bind(this)}
              />
            </div>
          </div>

          <ul className="list-group-items list-group  list-group-flush">
            <div className="table-responsive">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th scope="col">Expense</th>
                    <th style={{ width: "25%" }} scope="col">
                      Resource
                    </th>
                    <th style={{ width: "35%" }} scope="col">
                      Description
                    </th>
                    <th scope="col">Total ({expense.CurrencyCode})</th>
                    <th scope="col">Issued</th>
                    <th scope="col">Due</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{expense.InvoiceNumber}</td>
                    <td>{expense.Contact.Name}</td>
                    <td>{expense.Reference}</td>
                    <td>${expense.Total}</td>
                    <td>{format(new Date(expense.Date), "DD/MM/YYYY")}</td>
                    <td>{format(new Date(expense.DueDate), "DD/MM/YYYY")}</td>
                  </tr>
                </tbody>
              </table>
              {expense.LineItems.length > 0 ? (
                <Button
                  className="lineItemsButton"
                  onClick={() =>
                    this.setState({ showExpenseInfo: !showExpenseInfo })
                  }
                  variant="primary"
                  size="sm"
                  aria-controls="line-items"
                  aria-expanded={showExpenseInfo}
                  style={{ marginBottom: "1em", marginTop: "1em" }}
                >
                  Line Items
                </Button>
              ) : (
                <Button
                  variant="secondary"
                  size="sm"
                  style={{ marginBottom: "1em", marginTop: "1em" }}
                >
                  No Line Items
                </Button>
              )}

              <Collapse in={this.state.showExpenseInfo}>
                <div id="line-items">
                  <ul className="list-group">
                    <table className="table table-striped">
                      <thead className="thead-dark">
                        <tr>
                          <th style={{ width: "40%" }} scope="col">
                            Description
                          </th>
                          <th style={{ width: "10%" }} scope="col">
                            Amount $
                          </th>
                          <th style={{ width: "10%" }} scope="col">
                            Tax $
                          </th>
                          <th style={{ width: "10%" }} scope="col">
                            Acc Code
                          </th>
                          <th style={{ width: "30%" }} scope="col">
                            Activity Name
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {expense.LineItems.map((item) => {
                          return (
                            <tr key={item.Id}>
                              <td>{item.Description}</td>
                              <td>${item.Amount}</td>
                              <td>${item.TaxAmount}</td>
                              <td>{item.AccountCode}</td>
                              <td>{item.ActivityName}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </ul>
                </div>
              </Collapse>
            </div>
          </ul>
        </div>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  width: auto;
  padding: 5px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 2px 6px 2px;
  textshadow: 0px 5px 15px rgba(255, 255, 255, 0.5);
  margin-bottom: 1em;
`;

ExpenseItem.propTypes = {
  expense: PropTypes.object.isRequired,
  deleteExpense: PropTypes.func,
  updateSinglePayment: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => {
  return {
    deleteExpense: (expense) => dispatch(deleteExpense(expense)),
    updateSinglePayment: (expense) => dispatch(updateSinglePayment(expense)),
  };
};

export default connect(mapDispatchToProps, {
  deleteExpense,
  updateSinglePayment,
})(ExpenseItem);
