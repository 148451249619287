import axios from "axios";
import {
  DELETE_EXPENSE,
  DELETE_EXPENSES,
  EXPENSES_LOADING,
  GET_EXPENSES,
  POST_EXPENSES,
  UPDATE_EXPENSE_SINGLE_PAYMENT,
  UPDATE_EXPENSE_PAYMENTS,
} from "./types";

// Get ALL Expenses
export const getExpenses = (paymentMethod) => (dispatch) => {
  dispatch(setExpensesLoading());

  const getExpenses = async () => {

    try {
      let response = await axios.post("/api/postFunctions/workbook", { action: GET_EXPENSES });

      if (response === "none") {
        dispatch({
          type: GET_EXPENSES,
          payload: [],
        });
      } else {
        dispatch({
          type: GET_EXPENSES,
          payload: response.data,
        });
        dispatch({
          type: UPDATE_EXPENSE_PAYMENTS,
          payMethod: paymentMethod,
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  getExpenses();
};

// Delete Expense
export const deleteExpense = (Id) => (dispatch) => {
  dispatch({
    type: DELETE_EXPENSE,
    payload: Id,
  });
};

// Delete Expenses
export const deleteExpenses = (expenses) => (dispatch) => {
  dispatch({
    type: DELETE_EXPENSES,
    payload: expenses,
  });
};

// Post Expenses
export const postExpenses = (expenses) => (dispatch) => {
  dispatch({
    type: POST_EXPENSES,
    payload: expenses,
  });
};

// Expenses loading
export const setExpensesLoading = () => {
  return {
    type: EXPENSES_LOADING,
  };
};

export const updateSinglePayment = (Id, paymentMethod) => (dispatch) => {
  dispatch({
    type: UPDATE_EXPENSE_SINGLE_PAYMENT,
    payload: Id,
    payMethod: paymentMethod,
  });
};

export const updatePayments = (paymentMethod) => (dispatch) => {
  dispatch({
    type: UPDATE_EXPENSE_PAYMENTS,
    payMethod: paymentMethod,
  });
};
