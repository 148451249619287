import PropTypes from "prop-types";
import React, { Component } from "react";
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { Button, Col, Container, Row } from "react-bootstrap";
import { connect } from "react-redux";
import {
  deleteCreditorInvoices,
  getCreditorInvoices,
  postCreditorInvoices,
} from "../../actions/redux/creditorInvoiceActions";
import postWorkbook from "../../actions/workbook/post/expenses"; //workbook status update is the same as expenses
import postQuickBooks from "../../actions/quickbooks/exportQuickBooks/creditorInvoicesActions";
import Spinner from "../common/Spinner";
import {
  AllPosted,
  ConErr,
  ExportingQuickBooks,
  FinalizedWorkbook,
  Found,
  IssuesFound,
  Looking,
  NoneFound,
  PostingWorkbook,
  QuickBooks500,
  XerroNoErrors
} from "../conditionals/creditorInvoiceHeaderConditionals";
import QuickBooksButton from "./Button";
import CreditorInvoiceItem from "./CreditorInvoiceItem";
import ErrorItems from "./errors";

class CreditorInvoices extends Component {
  state = {
    exported: false,
    exportingToQuickBooks: false,
    postingToWorkbook: false,
    postedToWorkbook: false,
    showErrors: false,
    showButtons: true,
    showHeading: true,
    errors: [],
    errCount: 0,
    loading: false,
    xero500: false,
    modalShow: false
  };

  componentDidMount() {
    this.props.getCreditorInvoices();
  }


  handleShow = () => {
    this.setState({ modalShow: true });
  };

  // Event to post invoices to WorkBook
  async onPostClickWorkbook(event, creditorInvoices) {
    this.setState({ loading: true, postingToWorkbook: true });
    let res = await postWorkbook(event, creditorInvoices);
    let response = await res;
    this.setState({
      loading: false,
      showErrors: false,
      exported: true,
      postingToWorkbook: false,
      postedToWorkbook: true
    });
    this.props.deleteCreditorInvoices();
    return response;
  }

  onPostClickQuickBooks = async () => {
    const creditorInvoices = this.props.creditorInvoices;
    this.setState({ loading: true, exportingToQuickBooks: true });
    let res = await postQuickBooks(creditorInvoices);
    let response = await res;
    this.setState({ loading: false, exportingToQuickBooks: false });
    if (response === 500) {
      this.setState({
        exported: false,
        xero500: true
      });
      return;
    }
    if (response !== undefined || response === []) {
      this.setState({
        exported: true,
        xero500: false,
      });
    }
    if (response !== "none" || response !== 500) {
      this.setState({
        showErrors: true,
        errors: response
          .map(i => {
            return {
              creditorInvoice: i.CreditorInvoiceNumber,
              errors: i.Error
            };
          }),
      });
      //let errCount = 0
      if (response !== "none") {
        let errCount = response.length;
        this.setState({
          errCount: errCount
        });
        if (errCount > 0) {
          this.setState({ modalShow: true });
        }
      }
    }
    return response;
  };

  // Check for errors returned by QuickBooks.

  componentWillReceiveProps(nextProps) {
    if (this.props.invoice === 9 || nextProps.creditorInvoice === 9) {
      this.setState({ showButtons: false });
    }
  }

  render() {
    const { creditorInvoices, loading } = this.props.creditorInvoice;
    const {
      errors,
      showErrors,
      exported,
      showButtons,
      showHeading,
      xero500,
      errCount,
      postingToWorkbook,
      exportingToQuickBooks,
      postedToWorkbook
    } = this.state;
    let creditorInvoiceItems;
    let errorItems;

    // HANDLE MODAL
    let modalClose = () => this.setState({ modalShow: false });

    // If invoices are loading, push a spinner to the invoiceItems <div />
    if (creditorInvoices === null || loading || this.state.loading) {
      creditorInvoiceItems = <Spinner animation="border" variant="primary" />;
    }

    // If WorkBook returns an error, push error response to the invoiceItems <div />
    else if (creditorInvoices === 9) {
      creditorInvoiceItems = <ConErr />;
    }

    // If WorkBook returns invoices, push invoice response to the invoiceItems <div />
    else {
      if (creditorInvoices !== undefined) {
        creditorInvoiceItems = (
          <TransitionGroup> {
            creditorInvoices.map(credinvoice => (
              <CSSTransition
                key={credinvoice.Id}
                timeout={200}
                classNames="itemsAnimate"
              >
                <CreditorInvoiceItem key={credinvoice.Id} creditorInvoice={credinvoice} />
              </CSSTransition>
            ))
          }
          </TransitionGroup>
        )
      } else {
        creditorInvoiceItems = <FinalizedWorkbook />;
      }

      if (errors.length !== 0) {
        errorItems = errors.map(error => (
          <ErrorItems
            key={error.creditorInvoice}
            show={this.state.modalShow}
            onHide={modalClose}
            errors={errors}
          />
        ));
      }
      // else {
      //   errorItems = <AllPosted />;
      // }
    }

    // WORKBOOK BUTTON CONDITIONALS
    let workbookButton;
    let buttonStyle;
    let buttonTitle;
    if (loading) {
      workbookButton = null;
    }
    if (!loading && !exported) {
      buttonStyle = "btn btn-sm m-2 btn-danger";
      buttonTitle = "WARNING: Items not yet exported!";
    }
    if (!loading && exported && errCount !== 0) {
      buttonStyle = "btn btn-sm m-2 btn-outline-danger";
      buttonTitle = "WARNING: Items may have issues in QuickBooks!";
    }
    if (!loading && exported && errCount === 0) {
      buttonStyle = "btn btn-sm m-2 btn-success";
      buttonTitle = "";
    }
    if (!loading && !postedToWorkbook) {
      workbookButton = (
        <button
          style={{ float: "right" }}
          className={buttonStyle}
          onClick={e => this.onPostClickWorkbook(e, creditorInvoices)}
          title={buttonTitle}
        >
          {" "}
          Update in WorkBook
        </button>
      );
    }

    // QUICKBOOKS BUTTON CONDITIONALS
    let xeroButton;
    if (!loading && !exported) {
      xeroButton = (
        <QuickBooksButton
          buttonClass={`btn btn-sm m-2 btn-primary`}
          title={"Creditor Invoices Loaded, export to QuickBooks?"}
          onPostClickQuickBooks={this.onPostClickQuickBooks}
        />
      );
    }
    if (!loading && exported && showErrors && errCount !== 0) {
      xeroButton = (
        <Button variant="danger" onClick={this.handleShow}>
          Show Errors
        </Button>
      );
    }
    if (loading || (exported && !showErrors)) {
      xeroButton = null;
    }

    // HEADING TEXT CONDITIONALS

    let headingText;
    if (loading && !exported) {
      headingText = <Looking />;
    }
    if (exportingToQuickBooks) {
      headingText = <ExportingQuickBooks />;
    }
    if (postingToWorkbook) {
      headingText = <PostingWorkbook />;
    }
    if (
      !exported &&
      !this.state.loading &&
      creditorInvoices !== undefined &&
      creditorInvoices.length > 0
    ) {
      headingText = (
        <h4>
          <span style={{ color: "#4885ED" }}>{creditorInvoices.length}</span>
          <Found />
        </h4>
      );
    }
    if (
      !exported &&
      !loading &&
      creditorInvoices !== undefined &&
      creditorInvoices.length === 0
    ) {
      headingText = <NoneFound />;
    }
    if (exported && errCount === 0) {
      headingText = <XerroNoErrors />;
    }
    if (exported && errCount !== 0) {
      headingText = <IssuesFound />;
    }
    if (!loading && xero500 === true) {
      headingText = <QuickBooks500 />;
    }
    if (postedToWorkbook) {
      headingText = <AllPosted />;
    }

    return (
      <Container>
        {showHeading === true ? (
          <Row className="d-flex flex-column headingText">{headingText}</Row>
        ) : null}
        {creditorInvoices.length !== 0 &&
          showButtons &&
          !xero500 &&
          !this.state.loading &&
          creditorInvoices !== 9 ? (
          <Row className="justify-content-sm-left paymentButton">
            <Col className="xeroButton">{xeroButton}</Col>
            <Col className="workbookButton">{workbookButton}</Col>
          </Row>
        ) : null}
        {showErrors ? (
          <Row className="d-flex flex-column errorsContainer">{errorItems}</Row>
        ) : null}
        {!xero500 ? (
          <Row className="d-flex flex-column">
            {creditorInvoiceItems}
          </Row>
        ) : null}
      </Container>
    );
  }
}

CreditorInvoices.propTypes = {
  deleteCreditorInvoices: PropTypes.func,
  getCreditorInvoices: PropTypes.func.isRequired,
  creditorInvoice: PropTypes.object.isRequired,
  errors: PropTypes.object,
  postCreditorInvoices: PropTypes.func
};

const mapStateToProps = state => ({
  creditorInvoice: state.creditorInvoices,
  creditorInvoices: state.creditorInvoices.creditorInvoices,
  errors: state.errors
});

const mapDispatchToProps = dispatch => {
  return {
    postCreditorInvoices: creditorInvoices =>
      dispatch(postCreditorInvoices(creditorInvoices)),
    getCreditorInvoices: creditorInvoices =>
      dispatch(getCreditorInvoices(creditorInvoices)),
    deleteCreditorInvoices: creditorInvoices =>
      dispatch(deleteCreditorInvoices(creditorInvoices))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreditorInvoices);
