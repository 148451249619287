import axios from "axios";
import {
  DELETE_INVOICE,
  DELETE_INVOICES,
  GET_INVOICES,
  INVOICES_LOADING,
  POST_INVOICES,
} from "./types";

// GET ALL INVOICES
export const getInvoices = () => dispatch => {
  dispatch(setInvoicesLoading());

  const getInvoices = async () => {
    try {

      let response = await axios.post("/api/postFunctions/workbook", { action: GET_INVOICES });

      if (response.data === "none") {
        dispatch({
          type: GET_INVOICES,
          payload: []
        });
      } else {
        dispatch({
          type: GET_INVOICES,
          payload: response.data
        });
      }
    } catch (error) {
      dispatch({
        type: GET_INVOICES,
        payload: 9,
      });
    }
  };
  getInvoices();
};

// Delete Invoice
export const deleteInvoice = Id => dispatch => {
  dispatch({
    type: DELETE_INVOICE,
    payload: Id
  });
};

// Delete Invoices
export const deleteInvoices = invoices => dispatch => {
  dispatch({
    type: DELETE_INVOICES,
    payload: invoices
  });
};

// Post Invoices
export const postInvoices = invoices => dispatch => {
  dispatch({
    type: POST_INVOICES,
    payload: invoices
  });
};

// Invoices loading
export const setInvoicesLoading = () => {
  return {
    type: INVOICES_LOADING
  };
};