import PropTypes from "prop-types";
import React, { Component } from "react";
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { Button, Col, Container, Row } from "react-bootstrap";
import { connect } from "react-redux";
import {
  deleteExpenses,
  getExpenses,
  postExpenses,
  updatePayments,
} from "../../actions/redux/expenseActions";
import postWorkbook from "../../actions/workbook/post/expenses";
import postQuickBooks from "../../actions/quickbooks/exportQuickBooks/expenses";
import Spinner from "../common/Spinner";
import {
  AllPosted,
  ConErr,
  ExportingQuickBooks,
  FinalizedWorkbook,
  Found,
  IssuesFound,
  Looking,
  NoneFound,
  PostingWorkbook,
  QuickBooks500,
  XerroNoErrors,
} from "../conditionals/expenseHeaderConditionals";
import QuickBooksButton from "./Button";
import ErrorItems from "./errors";
import ExpenseItem from "./ExpenseItem";

class Expenses extends Component {
  state = {
    exported: false,
    exportingToQuickBooks: false,
    postingToWorkbook: false,
    postedToWorkbook: false,
    showErrors: false,
    showButtons: true,
    showHeading: true,
    errors: [],
    errCount: 0,
    loading: false,
    xero500: false,
    modalShow: false,
    paymentMethod: JSON.parse(localStorage.getItem("q_exp")) ?
      JSON.parse(localStorage.getItem("q_exp"))[0] :
      { acc_Id: "0", acc_Name: "None Found", acc_Type: "Nothing" },
  };

  componentDidMount() {
    this.props.getExpenses(this.state.paymentMethod);
  }

  handleShow = () => {
    this.setState({ modalShow: true });
  };

  handleUpdatePayments = (event) => {
    const paymentOption = JSON.parse(event.target.value)
    this.props.updatePayments(paymentOption);
    this.setState({ paymentMethod: paymentOption });
  };

  // Event to post expenses to WorkBook
  async onPostClickWorkbook(event, expenses) {
    this.setState({ loading: true, postingToWorkbook: true });
    let res = await postWorkbook(event, expenses);
    let response = await res;
    this.setState({
      loading: false,
      showErrors: false,
      exported: true,
      postingToWorkbook: false,
      postedToWorkbook: true,
    });
    this.props.deleteExpenses();
    return response;
  }
  onPostClickQuickBooks = async () => {
    const expenses = this.props.expenses;
    this.setState({ loading: true, exportingToQuickBooks: true });
    let res = await postQuickBooks(expenses);
    let response = await res;
    console.log("RESSS " + JSON.stringify(response, null, 2));
    this.setState({ loading: false, exportingToQuickBooks: false });
    if (response === 500 || response === undefined) {
      this.setState({
        exported: false,
        xero500: true,
      });
      return;
    }
    if (response !== undefined) {
      this.setState({
        exported: true,
        xero500: false,
      });
    }
    if (response != "none" || response !== 500) {
      this.setState({
        showErrors: true,
        errors: response.map((i) => {
          return {
            expense: i.expense,
            errors: i.error,
          };
        }),
      });
      //let errCount = 0
      if (response != "none") {
        let errCount = response.length;
        this.setState({
          errCount: errCount,
        });
        if (errCount > 0) {
          this.setState({ modalShow: true });
        }
      }
      if (response == "none") {
        this.setState({
          errCount: 0,
        });
        this.setState({ modalShow: false });
      }
    }
    return response;
  };

  // Check for errors returned by QuickBooks.

  componentWillReceiveProps(nextProps) {
    if (this.props.expense === 9 || nextProps.expense === 9) {
      this.setState({ showButtons: false });
    }
  }

  render() {
    const { expenses, loading } = this.props.expense;
    const {
      errors,
      showErrors,
      exported,
      showButtons,
      showHeading,
      xero500,
      errCount,
      postingToWorkbook,
      exportingToQuickBooks,
      postedToWorkbook,
      paymentMethod,
    } = this.state;
    let expenseItems;
    let errorItems;

    // HANDLE MODAL
    let modalClose = () => this.setState({ modalShow: false });

    // If expenses are loading, push a spinner to the expenseItems <div />
    if (expenses === null || loading || this.state.loading) {
      expenseItems = <Spinner animation="border" variant="primary" />;
    }

    // If WorkBook returns an error, push error response to the expenseItems <div />
    else if (expenses === 9) {
      expenseItems = <ConErr />;
    }

    // If WorkBook returns expenses, push expense response to the expenseItems <div />
    else {
      if (expenses !== undefined) {
        expenseItems = (
          <TransitionGroup> {
            expenses.map((expense) => (
              <CSSTransition
                key={expense.Id}
                timeout={200}
                classNames="itemsAnimate"
              >
                <ExpenseItem key={expense.Id} expense={expense} />
              </CSSTransition>
            ))
          }
          </TransitionGroup>
        )
      } else {
        expenseItems = <FinalizedWorkbook />;
      }

      if (errors.length !== 0) {
        errorItems = errors.map((error) => (
          <ErrorItems
            key={error.expense}
            show={this.state.modalShow}
            onHide={modalClose}
            errors={errors}
          />
        ));
      }
    }

    // WORKBOOK BUTTON CONDITIONALS
    let workbookButton;
    let buttonStyle;
    let buttonTitle;
    if (loading) {
      workbookButton = null;
    }
    if (!loading && !exported) {
      buttonStyle = "btn btn-sm m-2 btn-danger";
      buttonTitle = "WARNING: Expenses not yet exported!";
    }
    if (!loading && exported && errCount !== 0) {
      buttonStyle = "btn btn-sm m-2 btn-outline-danger";
      buttonTitle = "WARNING: Expenses may have issues in QuickBooks!";
    }
    if (!loading && exported && errCount === 0) {
      buttonStyle = "btn btn-sm m-2 btn-success";
      buttonTitle = "";
    }
    if (!loading && !postedToWorkbook) {
      workbookButton = (
        <button
          style={{ float: "right" }}
          className={buttonStyle}
          onClick={(e) => this.onPostClickWorkbook(e, expenses)}
          title={buttonTitle}
        >
          {" "}
          Update in WorkBook
        </button>
      );
    }

    // QUICKBOOKS BUTTON CONDITIONALS
    let xeroButton;
    if (!loading && !exported) {
      xeroButton = (
        <QuickBooksButton
          buttonClass={`btn btn-sm m-2 btn-primary`}
          title={"Expenses Loaded, export to QuickBooks?"}
          onPostClickQuickBooks={this.onPostClickQuickBooks}
        />
      );
    }
    if (!loading && exported && showErrors && errCount > 0) {
      xeroButton = (
        <Button variant="danger" onClick={this.handleShow}>
          Show Errors
        </Button>
      );
    }
    if (loading || (exported && !showErrors)) {
      xeroButton = null;
    }

    // HEADING TEXT CONDITIONALS
    let headingText;
    if (loading && !exported) {
      headingText = <Looking />;
    }
    if (exportingToQuickBooks) {
      headingText = <ExportingQuickBooks />;
    }
    if (postingToWorkbook) {
      headingText = <PostingWorkbook />;
    }
    if (
      !exported &&
      !this.state.loading &&
      expenses !== undefined &&
      expenses.length > 0
    ) {
      headingText = (
        <h4>
          <span style={{ color: "#4885ED" }}>{expenses.length}</span>
          <Found />
        </h4>
      );
    }
    if (
      !exported &&
      !loading &&
      expenses !== undefined &&
      expenses.length === 0
    ) {
      headingText = <NoneFound />;
    }
    if (exported && errCount === 0) {
      headingText = <XerroNoErrors />;
    }
    if (exported && errCount !== 0) {
      headingText = <IssuesFound />;
    }
    if (!loading && xero500 === true) {
      headingText = <QuickBooks500 />;
    }
    if (postedToWorkbook) {
      headingText = <AllPosted />;
    }

    // GET EXPENSE OPTIONS FROM LOCAL STORAGE. IF IT DOES NOT EXIST OR IS EMPTY, CREATE A BLANK ONE TO AVOID MAPPING ERRORS.
    let expensePaymentOptions = [paymentMethod];
    if (
      localStorage.getItem("q_exp") !== undefined &&
      localStorage.getItem("q_exp") !== null &&
      localStorage.getItem("q_exp").length !== 0
    ) {
      expensePaymentOptions = JSON.parse(localStorage.getItem("q_exp"));
    }

    return (
      <Container>
        {showHeading === true ? (
          <Row className="d-flex flex-column headingText">{headingText}</Row>
        ) : null}
        {expenses.length !== 0 &&
          showButtons &&
          !xero500 &&
          !this.state.loading &&
          expenses !== 9 ? (
          <Row className="justify-content-sm-left paymentButton">
            <Col className="xeroButton">{xeroButton}</Col>
            <Col className="workbookButton">{workbookButton}</Col>
          </Row>
        ) : null}
        {expenses.length !== 0 &&
          !exported &&
          showButtons &&
          expenses !== 9 &&
          !xero500 &&
          !loading &&
          !this.state.loading ? (
          <Row className="d-flex flex-column radioContainer">
            <Col xs={6}>
              Payment Method:
              <select
                value={JSON.stringify(paymentMethod)}
                onChange={this.handleUpdatePayments}
                className="form-control"
                id="paymentMethod"
              >
                {expensePaymentOptions.map((option) => {
                  return <option key={option.acc_Id} value={JSON.stringify(option)}>{option.acc_Name}</option>;
                })}
              </select>
            </Col>
          </Row>
        ) : null}
        {showErrors ? (
          <Row className="d-flex flex-column errorsContainer">{errorItems}</Row>
        ) : null}
        {!xero500 ? (
          <Row className="d-flex flex-column">
            {expenseItems}
          </Row>
        ) : null}
      </Container>
    );
  }
}

Expenses.propTypes = {
  deleteExpenses: PropTypes.func,
  getExpenses: PropTypes.func.isRequired,
  expense: PropTypes.object.isRequired,
  errors: PropTypes.object,
  postExpenses: PropTypes.func,
  updatePayments: PropTypes.func,
};

const mapStateToProps = (state) => ({
  expense: state.expenses,
  expenses: state.expenses.expenses,
  errors: state.errors,
});

const mapDispatchToProps = (dispatch) => {
  return {
    postExpenses: (expenses) => dispatch(postExpenses(expenses)),
    getExpenses: (expenses) => dispatch(getExpenses(expenses)),
    deleteExpenses: (expenses) => dispatch(deleteExpenses(expenses)),
    updatePayments: (expenses) => dispatch(updatePayments(expenses)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Expenses);
