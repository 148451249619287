import PropTypes from "prop-types";
import React, { Component } from "react";
import { Button, Container, Modal, Table } from "react-bootstrap";

class ErrorItems extends Component {
  constructor(props) {
    super(props);
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.state = {
      errors: [],
      modalShow: true
    };
  }
  handleClose() {
    this.setState({
      modalShow: false
    });
  }

  handleShow() {
    this.setState({ modalShow: true });
  }

  render() {
    const { errors } = this.props;
    console.log(errors)

    return (
      <Modal
        {...this.props}
        aria-labelledby="contained-modal-title-vcenter"
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Issues Found ...
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            "maxHeight": "calc(100vh - 210px)",
            "overflowY": "auto"
          }}
        >
          <Container>
            <Table style={{ fontSize: "8pt" }} responsive="sm">
              <thead>
                <tr>
                  <th>Credit Note</th>
                  <th>Errors</th>
                </tr>
              </thead>
              {errors.map(errors => {
                return (
                  <tbody key={errors.creditNote}>
                    <tr>
                      <td>{errors.creditNote}</td>
                      <td><ul>{errors.errors.map((error, i) => <li key={i}>{error}</li>)}</ul></td>
                    </tr>
                  </tbody>
                );
              })}
            </Table>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this.props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

ErrorItems.propTypes = {
  errors: PropTypes.array
};

export default ErrorItems;
