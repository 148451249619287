import { faCheck, faTimes, faInfoCircle, faUserPlus, faKey, faHome } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { logoutUser } from "../../actions/redux/authActions";
import { clearCurrentProfile } from "../../actions/redux/profileActions";
import QuickBooksBadge from "./QuickBooksBadge";

class Navbar extends Component {
  state = {
    xeroAuth: false
  };

  async componentDidMount() {
    const token_expiry = localStorage.expires_in;
      // Get the QuickBooks Auth time stamp from the user in the DB and convert to ms
      if (
        token_expiry !== undefined &&
        token_expiry !== ""
      ) {
        // Compare the users existing QuickBooks Auth timestamp to the current date in ms
        // If their current is before now, the xero auth button will show as red / disconnected
        if (token_expiry <= Date.now() ) {
          this.setState({ xeroAuth: false });
        } else {
          this.setState({ xeroAuth: true });
        }
      } else {
        this.setState({ xeroAuth: false });
      }
  }

  componentWillReceiveProps(props) {
    const { refresh, id } = this.props;
    if (props.refresh !== refresh) {
      this.fetchShoes(id).then(this.refreshShoeList);
    }
  }

  onLogoutClick(e) {
    e.preventDefault();
    this.props.clearCurrentProfile();
    this.props.logoutUser();
  }

  render() {
    const { isAuthenticated, user } = this.props.auth;
    const { xeroAuth } = this.state;

    // User Admin role gains access to add user link
    const adminLink = (
      <li style={{ marginLeft: "1px" }} className="nav-item">
        <a
          href="/register"
          className="nav-link"
        >
          <FontAwesomeIcon icon={faUserPlus} /> {"  "}
          Add User
        </a>
      </li>
    );

    const authLinks = (
      <ul className="navbar-nav ml-auto">
        {xeroAuth ? (
          <QuickBooksBadge icon={faCheck} color={"#7bb346"} />
        ) : (
          <QuickBooksBadge icon={faTimes} color={"#e60000"} />
        )}
        <li style={{ marginLeft: "1px" }} className="nav-item">
          <a
            href="/dashboard"
            className="nav-link"
          >
            <FontAwesomeIcon icon={faHome} /> {"  "}
            Home
          </a>
        </li>
        {
          user.role === "admin" && adminLink
        }
        <li style={{ marginLeft: "1px" }} className="nav-item">
          <a
            href="/updatepassword"
            className="nav-link"
          >
            <FontAwesomeIcon icon={faKey} /> {"  "}
            Update Password
          </a>
        </li>
        <li style={{ marginLeft: "1px" }} className="nav-item">
          <a
            href="/help"
            className="nav-link"
          >
            <FontAwesomeIcon icon={faInfoCircle} /> {"  "}
            Help
          </a>
        </li>
        <li style={{ marginLeft: "1px" }} className="nav-item">
          <a
            href="/"
            onClick={this.onLogoutClick.bind(this)}
            className="nav-link"
          >
            <img
              className="rounded-circle"
              src={user.avatar}
              alt={user.name}
              style={{ width: "25px", marginRight: "5px" }}
              title="You must have a Gravatar connected to your email to display an image"
            />{" "}
            Logout
          </a>
        </li>
      </ul>
    );

    const guestLinks = (
      <ul className="navbar-nav ml-auto">
        <li className="nav-item">
          <NavLink className="nav-link" to="/login">
            Login
          </NavLink>
        </li>
      </ul>
    );

    return (
      <nav
        className="navbar navbar-expand-sm navbar-dark bg-dark mb-4 nav-container"
        style={{ position: "relative", width: "100%" }}
      >
        <div
          style={{
            position: "relative",
            float: "left",
            marginLeft: isAuthenticated ? "80px" : "10px",
            fontSize: "20pt"
          }}
        >
          <ul className="navbar-nav ml-auto">
            {
              isAuthenticated ?
                <li className="navbar-brand">
                  Exports | Config
                </li> :
                <NavLink className="navbar-brand" to="/">
                  QuickBooks Connector by Smartsoftware
                </NavLink>
            }
          </ul>
        </div>
        <div
          style={{
            position: "relative",
            float: "right"
          }}
          className="collapse navbar-collapse"
          id="mobile-nav"
        >
          {isAuthenticated ? authLinks : guestLinks}
        </div>
      </nav>
    );
  }
}

Navbar.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  profile: PropTypes.object
};

const mapStateToProps = state => ({
  auth: state.auth,
  profile: state.profile,
  xeroAuth: state.xeroAuth
});

export default connect(mapStateToProps, { logoutUser, clearCurrentProfile })(
  Navbar
);
