import TextField from "@material-ui/core/TextField";
import axios from "axios";
import React, { Component, Fragment } from "react";
import { inputStyle, LinkButtons, SubmitButtons } from "./authComponents";

class ForgotPassword extends Component {
  constructor() {
    super();

    this.state = {
      email: "",
      showError: false,
      messageFromServer: "",
      showNullError: false,
      success: false
    };
  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };

  sendEmail = e => {
    e.preventDefault();
    const { email } = this.state;
    if (email === "") {
      this.setState({
        showError: false,
        messageFromServer: "",
        showNullError: true,
        success: false
      });
    } else {
      axios
        .post("/api/passwords/forgot_Password", {
          email
        })
        .then(response => {
          if (response.data === "recovery email sent") {
            this.setState({
              showError: false,
              messageFromServer: "recovery email sent",
              showNullError: false,
              success: true
            });
          }
        })
        .catch(error => {
          console.error(error.response.data);
          if (error.response.data === "email not in db") {
            this.setState({
              showError: true,
              messageFromServer: "",
              showNullError: false
            });
          }
        });
    }
  };

  render() {
    const {
      email,
      messageFromServer,
      showNullError,
      showError,
      success
    } = this.state;

    return (
      <div style={{ marginTop: "5em", textAlign: "center" }}>
        {!success && (
          <Fragment>
            <Fragment>
              <p style={{ marginBottom: "5em" }}>
                Please enter the email address for your account below
              </p>
            </Fragment>
            <form className="profile-form" onSubmit={this.sendEmail}>
              <TextField
                style={inputStyle}
                id="email"
                label="email"
                value={email}
                onChange={this.handleChange("email")}
                placeholder="Email Address"
              />
              <SubmitButtons
                buttonStyle={{
                  marginTop: "1.5em",
                  marginLeft: "1em",
                  backgroundColor: "#4885ED"
                }}
                buttonText="Send Email"
              />
            </form>
          </Fragment>
        )}
        {showNullError && (
          <div style={{ marginTop: "1em" }}>
            <p className="errorStyle">The email address cannot be empty.</p>
          </div>
        )}
        {showError && (
          <div>
            <p style={{ marginTop: "3em" }}>
              The supplied email address isn&apos;t recognized.
            </p>
            <br />
            <p>
              Please contact Smartsoftware support at
              <a style={{ color: '#4885ED' }} href="mailto: support@smartsoftware.net"> support@smartsoftware.net</a>
              &nbsp;to register for a new account.
            </p>
          </div>
        )}
        {messageFromServer === "recovery email sent" && (
          <div>
            <p style={{ marginTop: "4em" }}>
              <span style={{ color: "#28A745" }}>
                Password Reset Email Successfully Sent!
              </span>
            </p>
            <LinkButtons
              buttonText="Back to login"
              link="/login"
              buttonStyle={{ backgroundColor: "#4885ED", marginTop: "1.5em" }}
            />
          </div>
        )}
      </div>
    );
  }
}

export default ForgotPassword;
