import axios from "axios";
import {
  GET_CREDIT_NOTES,
  DELETE_CREDIT_NOTE,
  DELETE_CREDIT_NOTES,
  POST_CREDIT_NOTES,
  CREDIT_NOTES_LOADING,
} from "./types";

// GET ALL CREDIT NOTES
export const getCreditNotes = () => dispatch => {
  dispatch(setCreditNotesLoading());

  const getCreditNotes = async () => {
    try {

      let response = await axios.post("/api/postFunctions/workbook", { action: GET_CREDIT_NOTES });

      if (response.data === "none") {
        dispatch({
          type: GET_CREDIT_NOTES,
          payload: []
        });
      } else {
        dispatch({
          type: GET_CREDIT_NOTES,
          payload: response.data
        });
      }
    } catch (error) {
      dispatch({
        type: GET_CREDIT_NOTES,
        payload: 9,
      });
    }
  };
  getCreditNotes();
};

// Delete Credit Note
export const deleteCreditNote = Id => dispatch => {
  dispatch({
    type: DELETE_CREDIT_NOTE,
    payload: Id
  });
};

// Delete Credit Notes
export const deleteCreditNotes = creditNotes => dispatch => {
  dispatch({
    type: DELETE_CREDIT_NOTES,
    payload: creditNotes
  });
};

// Post Credit Notes
export const postCreditNotes = creditNotes => dispatch => {
  dispatch({
    type: POST_CREDIT_NOTES,
    payload: creditNotes
  });
};

// Credit Notes loading
export const setCreditNotesLoading = () => {
  return {
    type: CREDIT_NOTES_LOADING
  };
};