import PropTypes from "prop-types";
import React, { Component } from "react";
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { Button, Col, Container, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { deleteCreditNotes, getCreditNotes, postCreditNotes } from "../../actions/redux/creditNoteActions";
import postQuickBooks from "../../actions/quickbooks/exportQuickBooks/creditNoteActions";
import postWorkbook from "../../actions/workbook/post/invoices"; //workbook status update is the same as invoices
import Spinner from "../common/Spinner";
import { 
  AllPosted, 
  ConErr, 
  ExportingQuickBooks, 
  FinalizedWorkbook, 
  Found, 
  IssuesFound, 
  Looking, 
  NoneFound, 
  PostingWorkbook, 
  QuickBooks500, 
  QuickBooksNoErrors 
} from "../conditionals/creditNoteHeaderConditionals";
import QuickBooksButton from "./Button";
import ErrorItems from "./errors";
import CreditNoteItem from "./CreditNoteItem";

class CreditNotes extends Component {
  state = {
    exported: false,
    exportingToQuickBooks: false,
    postingToWorkbook: false,
    postedToWorkbook: false,
    showErrors: false,
    showButtons: true,
    showHeading: true,
    errors: [],
    errCount: 0,
    loading: false,
    xero500: false,
    modalShow: false
  };

  componentDidMount() {
    this.props.getCreditNotes();
  }


  handleShow = () => {
    this.setState({ modalShow: true });
  };

  // Event to post Credit Notes to WorkBook
  async onPostClickWorkbook(event, creditNotes) {
    this.setState({ loading: true, postingToWorkbook: true });
    let res = await postWorkbook(event, creditNotes);
    let response = await res;
    this.setState({
      loading: false,
      showErrors: false,
      exported: true,
      postingToWorkbook: false,
      postedToWorkbook: true
    });
    this.props.deleteCreditNotes();
    return response;
  }

  onPostClickQuickBooks = async () => {
    const creditNotes = this.props.creditNotes;
    this.setState({ loading: true, exportingToQuickBooks: true });
    let res = await postQuickBooks(creditNotes);
    let response = await res;
    this.setState({ loading: false, exportingToQuickBooks: false });
    if (response === 500) {
      this.setState({
        exported: false,
        xero500: true
      });
      return;
    }
    if (response !== undefined || response === []) {
      this.setState({
        exported: true,
        xero500: false
      });
    }
    if (response !== "none" || response !== 500) {
      this.setState({
        showErrors: true,
        errors: response
          .map(i => {
            return {
              creditNote: i.CreditNoteNumber,
              errors: i.Error
            };
          }),
      });

      if (response !== "none") {
        let errCount = response.length;
        this.setState({
          errCount: errCount
        });
        if (errCount > 0) {
          this.setState({ modalShow: true });
        }
      }
    }
    return response;
  };

  // Check for errors returned by QuickBooks.

  componentWillReceiveProps(nextProps) {
    if (this.props.creditNote === 9 || nextProps.creditNote === 9) {
      this.setState({ showButtons: false });
    }
  }

  render() {
    const { creditNotes, loading } = this.props.creditNote;
    const {
      errors,
      showErrors,
      exported,
      showButtons,
      showHeading,
      xero500,
      errCount,
      postingToWorkbook,
      exportingToQuickBooks,
      postedToWorkbook
    } = this.state;
    let creditNoteItems;
    let errorItems;


    // HANDLE MODAL
    let modalClose = () => this.setState({ modalShow: false });

    // If creditNotes are loading, push a spinner to the creditNoteItems <div />
    if (creditNotes === null || loading || this.state.loading) {
      creditNoteItems = <Spinner animation="border" variant="primary" />;
    }

    // If WorkBook returns an error, push error response to the CreditNoteItems <div />
    else if (creditNotes === 9) {
      creditNoteItems = <ConErr />;
    }

    // If WorkBook returns Credit Notes, push credit note response to the CreditNoteItems <div />
    else {
      if (creditNotes !== undefined) {
        creditNoteItems = (
          <TransitionGroup> {
            creditNotes.map(creditNote => (
              <CSSTransition
                key={creditNote.Id}
                timeout={200}
                classNames="itemsAnimate"
              >
                <CreditNoteItem key={creditNote.Id} creditNote={creditNote} />
              </CSSTransition>
            ))
          }
          </TransitionGroup>
        )
      } else {
        creditNoteItems = <FinalizedWorkbook />;
      }

      if (errors.length !== 0) {
        errorItems = errors.map(error => (
          <ErrorItems
            key={error.creditNote}
            show={this.state.modalShow}
            onHide={modalClose}
            errors={errors}
          />
        ));
      }
    }

    // WORKBOOK BUTTON CONDITIONALS
    let workbookButton;
    let buttonStyle;
    let buttonTitle;
    if (loading) {
      workbookButton = null;
    }
    if (!loading && !exported) {
      buttonStyle = "btn btn-sm m-2 btn-danger";
      buttonTitle = "WARNING: Credit Notes not yet exported!";
    }
    if (!loading && exported && errCount !== 0) {
      buttonStyle = "btn btn-sm m-2 btn-outline-danger";
      buttonTitle = "WARNING: Credit Notes may have issues in QuickBooks!";
    }
    if (!loading && exported && errCount === 0) {
      buttonStyle = "btn btn-sm m-2 btn-success";
      buttonTitle = "";
    }
    if (!loading && !postedToWorkbook) {
      workbookButton = (
        <button
          style={{ float: "right" }}
          className={buttonStyle}
          onClick={e => this.onPostClickWorkbook(e, creditNotes)}
          title={buttonTitle}
        >
          {" "}
          Update in WorkBook
        </button>
      );
    }

    // QUICKBOOKS BUTTON CONDITIONALS
    let xeroButton;
    if (!loading && !exported) {
      xeroButton = (
        <QuickBooksButton
          buttonClass={`btn btn-sm m-2 btn-primary`}
          title={"Credit Notes Loaded, export to QuickBooks?"}
          onPostClickQuickBooks={this.onPostClickQuickBooks}
        />
      );
    }
    if (!loading && exported && showErrors && errCount !== 0) {
      xeroButton = (
        <Button variant="danger" onClick={this.handleShow}>
          Show Errors
        </Button>
      );
    }
    if (loading || (exported && !showErrors)) {
      xeroButton = null;
    }

    // HEADING TEXT CONDITIONALS
    let headingText;
    if (loading && !exported) {
      headingText = <Looking />;
    }
    if (exportingToQuickBooks) {
      headingText = <ExportingQuickBooks />;
    }
    if (postingToWorkbook) {
      headingText = <PostingWorkbook />;
    }
    if (
      !exported &&
      !this.state.loading &&
      creditNotes !== undefined &&
      creditNotes.length > 0
    ) {
      headingText = (
        <h4>
          <span style={{ color: "#4885ED" }}>{creditNotes.length}</span>
          <Found />
        </h4>
      );
    }
    if (
      !exported &&
      !loading &&
      creditNotes !== undefined &&
      creditNotes.length === 0
    ) {
      headingText = <NoneFound />;
    }
    if (exported && errCount === 0) {
      headingText = <QuickBooksNoErrors />;
    }
    if (exported && errCount !== 0) {
      headingText = <IssuesFound />;
    }
    if (!loading && xero500 === true) {
      headingText = <QuickBooks500 />;
    }
    if (postedToWorkbook) {
      headingText = <AllPosted />;
    }

    return (
      <Container>
        {showHeading === true ? (
          <Row className="d-flex flex-column headingText">{headingText}</Row>
        ) : null}
        {creditNotes.length !== 0 &&
          showButtons &&
          !xero500 &&
          !this.state.loading &&
          creditNotes !== 9 ? (
          <Row className="justify-content-sm-left paymentButton">
            <Col className="xeroButton">{xeroButton}</Col>
            <Col className="workbookButton">{workbookButton}</Col>
          </Row>
        ) : null}

        {showErrors ? (
          <Row className="d-flex flex-column errorsContainer">{errorItems}</Row>
        ) : null}
        {!xero500 ? (
          <Row className="d-flex flex-column">
            {creditNoteItems}
          </Row>
        ) : null}
      </Container>
    );
  }
}

CreditNotes.propTypes = {
  deleteCreditNotes: PropTypes.func,
  getCreditNotes: PropTypes.func.isRequired,
  creditNote: PropTypes.object.isRequired,
  errors: PropTypes.object,
  postCreditNotes: PropTypes.func
};

const mapStateToProps = state => ({
  creditNote: state.creditNotes,
  creditNotes: state.creditNotes.creditNotes,
  profile: state.profile,
  errors: state.errors
});

const mapDispatchToProps = dispatch => {
  return {
    postCreditNotes: creditNotes => dispatch(postCreditNotes(creditNotes)),
    getCreditNotes: creditNotes => dispatch(getCreditNotes(creditNotes)),
    deleteCreditNotes: creditNotes => dispatch(deleteCreditNotes(creditNotes)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreditNotes);
