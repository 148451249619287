import axios from "axios";

const postWorkbook = async function onPostClickWorkbook(event, invoices) {
  try {
    const invoicesToPostToWorkbook = invoices.map(ids => ids.Id);
    const toPost = { invoicesToPostToWorkbook };
    const response = await axios.post("/api/workbook/invoices", toPost);
    return response.data;
  } catch (e) {
    console.log(e);
    return false
  }
};

export default postWorkbook;
