import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React from 'react';

const QuickBooksBadge = ({ icon, color }) => (
  <div style={{ marginTop: '0.25em', width: '70px' }}>
    <FontAwesomeIcon
      icon={icon}
      color={color}
      size={'2x'}
      style={{ width: '100px' }}
    />
  </div>
);

QuickBooksBadge.propTypes = {
  icon: PropTypes.object.isRequired,
  color: PropTypes.string.isRequired
};

export default QuickBooksBadge;
