import {
  DELETE_INVOICE,
  DELETE_INVOICES,
  GET_INVOICES,
  INVOICES_LOADING,
  POST_INVOICES,
} from "../types";

const initialState = {
  invoices: [],
  loading: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case INVOICES_LOADING:
      return {
        ...state,
        loading: true
      };
    case GET_INVOICES:
      return {
        ...state,
        invoices: action.payload,
        loading: false
      };
    case DELETE_INVOICE:
      return Object.assign({}, state, {
        invoices: [
          ...state.invoices.filter(invoice => invoice.Id !== action.payload)
        ]
      });
    case DELETE_INVOICES:
      return { invoices: [] };
    case POST_INVOICES:
      break;
    default:
      return state;
  }
}
