import {
  GET_EXPENSES,
  DELETE_EXPENSE,
  DELETE_EXPENSES,
  EXPENSES_LOADING,
  POST_EXPENSES,
  UPDATE_EXPENSE_SINGLE_PAYMENT,
  UPDATE_EXPENSE_PAYMENTS
} from "../types";

const initialState = {
  expenses: [],
  loading: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case EXPENSES_LOADING:
      return {
        ...state,
        loading: true
      };
    case GET_EXPENSES:
      return {
        ...state,
        expenses: action.payload,
        loading: false
      };
    case DELETE_EXPENSE:
      return Object.assign({}, state, {
        expenses: [
          ...state.expenses.filter(expense => expense.Id !== action.payload)
        ]
      });
      case UPDATE_EXPENSE_SINGLE_PAYMENT:
        return {
          expenses: [
            ...state.expenses.map(item => {
              if (item.Id === action.payload) {
                item.AccountRef.AccountCode = action.payMethod.acc_Id;
                item.AccountRef.AccountName = action.payMethod.acc_Name;
                item.PayMethod = action.payMethod.acc_Type;
              }
              return item;
            })
          ]
        };
        case UPDATE_EXPENSE_PAYMENTS:
          return {
            expenses: [
              ...state.expenses.map(item => {
                item.AccountRef.AccountCode = action.payMethod.acc_Id;
                item.AccountRef.AccountName = action.payMethod.acc_Name;
                item.PayMethod = action.payMethod.acc_Type;
                return item;
              })
            ]
          };
    case DELETE_EXPENSES:
      return { expenses: [] };
    case POST_EXPENSES:
      break;
    default:
      return state;
  }
}
