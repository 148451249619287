import format from "date-fns/format";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Button, Collapse } from "react-bootstrap";
import { connect } from "react-redux";
import styled from "styled-components";
import {
  deleteInvoice
} from "../../actions/redux/invoiceActions";

class InvoiceItem extends Component {
  state = {
    showInvoiceInfo: false
  };

  onDeleteClick(e) {
    this.props.deleteInvoice(this.props.invoice.Id);
  }

  render() {
    const { invoice } = this.props;
    const { showInvoiceInfo/*, isDraftChecked, isAuthorisedChecked*/ } = this.state;
    return (
      <Wrapper>
        <div className="card card-header bg-light">
          <div>
            <div className="radio-status item-radio">Invoice #: {invoice.InvoiceNumber}</div>
            <div
              className="item-delete"
              style={{
                textAlign: "right",
                color: "red",
                cursor: "pointer"
              }}
            >
              <i
                className="fa fa-times"
                aria-hidden="true"
                onClick={this.onDeleteClick.bind(this)}
              />
            </div>
          </div>

          <ul className="list-group-items list-group  list-group-flush">
            <div className="table-responsive">
              <table className="table table-striped">
                <thead>
                  <tr>
                    {/* <th scope="col">Invoice</th> */}
                    <th style={{ width: "17%" }} scope="col">
                      Client
                    </th>
                    <th style={{ width: "20%" }} scope="col">
                      Contact
                    </th>
                    <th style={{ width: "33%" }} scope="col">
                      Job
                    </th>
                    <th scope="col">Total ({invoice.CurrencyCode})</th>
                    <th scope="col">Issued</th>
                    <th scope="col">Due</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    {/* <td>{invoice.InvoiceNumber}</td> */}
                    <td>{invoice.Contact.Name}</td>
                    <td>{invoice.Contact.Email ? invoice.Contact.Email : "-"}</td>
                    <td>{invoice.Reference}</td>
                    <td>${invoice.Total}</td>
                    <td>{format(new Date(invoice.Date), "DD/MM/YYYY")}</td>
                    <td>{format(new Date(invoice.DueDate), "DD/MM/YYYY")}</td>
                  </tr>
                </tbody>
              </table>
              <Button
                className="lineItemsButton"
                onClick={() =>
                  this.setState({ showInvoiceInfo: !showInvoiceInfo })
                }
                variant="primary"
                size="sm"
                aria-controls="line-items"
                aria-expanded={showInvoiceInfo}
                style={{ marginBottom: "1em", marginTop: "1em" }}
              >
                Line Items
              </Button>
              <Collapse in={this.state.showInvoiceInfo}>
                <div id="line-items">
                  <ul className="list-group">
                    <table className="table table-striped">
                      <thead className="thead-dark">
                        <tr>
                          <th style={{ width: "35%" }} scope="col">
                            Description
                          </th>
                          <th style={{ width: "5%" }} scope="col">
                            Qty
                          </th>
                          <th style={{ width: "10%" }} scope="col">
                            Rate
                          </th>
                          <th style={{ width: "10%" }} scope="col">
                            Amount $
                          </th>
                          <th style={{ width: "10%" }} scope="col">
                            Tax $
                          </th>
                          <th style={{ width: "10%" }} scope="col">
                            Activity Code
                          </th>
                          <th style={{ width: "20%" }} scope="col">
                            Activity Name
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {invoice.LineItems.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td>{item.Description}</td>
                              <td>{item.Quantity ? item.Quantity: '-'}</td>
                              <td>{item.Rate ? `$${item.Rate}`: '-'}</td>
                              <td>${item.AmountNet}</td>
                              <td>${item.TaxAmount}</td>
                              <td>{item.ActivityId}</td>
                              <td>{item.ActivityName}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </ul>
                </div>
              </Collapse>
            </div>
          </ul>
        </div>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  width: auto;
  padding: 5px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 2px 6px 2px;
  textshadow: 0px 5px 15px rgba(255, 255, 255, 0.5);
  margin-bottom: 1em;
`;

InvoiceItem.propTypes = {
  invoice: PropTypes.object.isRequired,
  deleteInvoice: PropTypes.func
};

const mapDispatchToProps = dispatch => {
  return {
    deleteInvoice: invoice => dispatch(deleteInvoice(invoice))
  };
};

export default connect(mapDispatchToProps, {
  deleteInvoice
})(InvoiceItem);
