import axios from "axios";
import React, { Component } from "react";
import { withRouter } from "react-router";
import connected from "../../img/connected.gif";
import "./animate.css";
import { getAccounts } from "../../actions/quickbooks/exportQuickBooks/accountsActions";

class Quickbooks extends Component {
  async componentDidMount() {
    const url = window.location.href;
    const getToken = await axios.get("/api/callback", { params: { url: url } });

    // STORE CONNECTION INFO
    localStorage.setItem(
      "refresh_token",
      JSON.stringify(getToken.data.refresh_token)
    );
    localStorage.setItem(
      "x_refresh_token_expires_in",
      JSON.stringify(getToken.data.x_refresh_token_expires_in)
    );
    localStorage.setItem("responseURL", JSON.stringify(getToken.config.params));
    localStorage.setItem("access_token", getToken.data.access_token);
    localStorage.setItem("expires_in", Date.now() + 2700000);

    try {
      // GET ACCOUNTS AND POSSIBLE PO# CUISTOM FIELD
      let res = await getAccounts();
      // STORE OUR ACCOUNTS FIELD IN LS TO BE USED WITHIN PURCHASES AND EXPENSES
      localStorage.setItem("q_exp", JSON.stringify(res.data[0]));
      localStorage.setItem("q_acc", JSON.stringify(res.data[1]));
      // IF A PO# CUSTOM FIELD EXISTS STORE THEM FOR USE IN INVOICES AND CREDIT NOTES
      localStorage.setItem("q_custPO", JSON.stringify(res.data[2]));
    } catch (e) {
      //TODO Handle this error better
      console.log(e);
    }

    // REDIRECT TO DASHBOARD
    this.redirectTimeout = setTimeout(() => {
      window.location.replace('/dashboard');
    }, 1500);
  }

  render() {
    return (
      <div style={{ textAlign: "center", top: "200px", position: "relative" }}>
        <h1 style={{ color: "#5c5969" }}>Connected!</h1>
        <img
          style={{ width: "100px", height: "100px", marginTop: "2em" }}
          src={connected}
          alt="connected to quickbooks"
        />
      </div>
    );
  }
}

export default withRouter(Quickbooks);
