import axios from "axios";
import { EXPORT_PURCHASES } from "../../redux/types";

const onPostClickQuickBooks = async function onPostClickQuickBooks(purchases) {
  try {
    // REBUID PURCHASES
    let bId = 1;
    const purchasesMapped = {
      BatchItemRequest: purchases.map((i) => {
        return {
          bId: "bid" + bId++,
          operation: "create",
          "PurchaseOrder": {
            DocNumber: i.PurchaseOrderNumber,
            APAccountRef: {
              value: i.AccountAP.AccountCode,
              name: i.AccountAP.DisplayName,
            },
            TxnDate: i.Date,
            Line: i.LineItems.map((l) => {
              return {
                Description: l.Description,
                DetailType: "ItemBasedExpenseLineDetail",
                ItemBasedExpenseLineDetail: {
                  ItemRef: {
                    name: l.ActivityName
                  },
                  Qty: l.Quantity,
                  UnitPrice: l.UnitAmount,
                },
                Amount: l.Quantity * l.UnitAmount,
              };
            }),
            DueDate: i.DeliveryDate,
            VendorRef: {
              name: i.Contact.Name,
            },
            PrivateNote: i.Reference,
          }
        };
      }),
    };


    if (localStorage.getItem('access_token') === null || localStorage.getItem('responseURL') === null) {
      return 500;
    }



    // GET realmId (company ID), expiry and refresh token
    const realmId = localStorage.responseURL
      .split("realmId=")
      .pop()
      .split('"')[0]
      .toString();
    const access_token = localStorage.access_token;
    const toPost = { purchasesMapped, realmId, access_token };

    // Send invoice data
    let res = await axios.post("/api/postFunctions/quickbooks", { action: EXPORT_PURCHASES, toPost: toPost });


    return await res.data;

    // TEST DATA FOR ERROR TESTS
    // const testData = [{"bId":"bid1","Error":["Invalid Reference Id : Something you're trying to use has been made inactive. Check the fields with accounts, customers, items, vendors or employees."],"InvoiceNumber":"INV227667"},{"bId":"bid2","Error":["Invalid Reference Id : Something you're trying to use has been made inactive. Check the fields with accounts, customers, items, vendors or employees."],"InvoiceNumber":"INV2755"}] 
    // return testData;


  } catch (e) {
    console.log(e);
    return 500;
  }
};

export default onPostClickQuickBooks;
