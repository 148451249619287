import {
    DELETE_CREDIT_NOTE,
    DELETE_CREDIT_NOTES,
    GET_CREDIT_NOTES,
    CREDIT_NOTES_LOADING,
    POST_CREDIT_NOTES,
  } from "../types";
  
  const initialState = {
    creditNotes: [],
    loading: false
  };
  
  export default function(state = initialState, action) {
    switch (action.type) {
      case CREDIT_NOTES_LOADING:
        return {
          ...state,
          loading: true
        };
      case GET_CREDIT_NOTES:
        return {
          ...state,
          creditNotes: action.payload,
          loading: false
        };
      case DELETE_CREDIT_NOTE:
        return Object.assign({}, state, {
          creditNotes: [
            ...state.creditNotes.filter(creditNote => creditNote.Id !== action.payload)
          ]
        });
      case DELETE_CREDIT_NOTES:
        return { creditNotes: [] };
      case POST_CREDIT_NOTES:
        break;
      default:
        return state;
    }
  }
  