import axios from "axios";

const postWorkbook = async function onPostClickWorkbook(event, expenses) {
  try {
    const expensesToPostToWorkbook = expenses.map(ids => ids.Id);
    const toPost = { expensesToPostToWorkbook };
    const response = await axios.post("/api/workbook/expenses", toPost);
    return response;
  } catch (e) {
    console.log(e);
  }
};

export default postWorkbook;
