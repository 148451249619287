import React, { Component } from "react";
import { Link } from "react-router-dom";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import logo from '../../img/ss_logo.jpg';

class Landing extends Component {
  componentDidMount() {
    if (this.props.auth.isAuthenticated) {
      this.props.history.push("/dashboard");
    }
  }


  render() {
    return (
      <div className="landing">
        <div className="dark-overlay landing-inner text-dark">
          <div
            style={{
              backgroundColor: "white",
              padding: "4rem",
              opacity: 0.9
            }}
            className="container"
          >
            <div className="row">
              <div className="col-md-12 text-center">
                <h3 className="display-4 mb-4">
                  QuickBooks Connector by
                </h3>
                <img
                  style={{
                    maxWidth: "75%",
                    maxHeight: "auto",
                    paddingBottom: "2rem"
                  }}
                  src={logo}
                  alt="Smartsofware Logo"
                />
                <p className="lead"> Bring WorkBook and QuickBooks together!</p>
                <hr />
                <Link to="/login" className="btn btn-lg btn-primary mr-2">
                  Login
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Landing.propTypes = {
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps)(Landing);
