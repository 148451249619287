import PropTypes from "prop-types";
import React, { Component } from "react";
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { Col, Container, Row } from "react-bootstrap";
import { connect } from "react-redux";
import postWorkbookPayments from "../../actions/workbook/post/invoicePayments";
import {
  deleteQuickBooksInvoices,
  getQuickBooksInvoices,
  postInvoices
} from "../../actions/quickbooks/fetchQuickBooks/getInvoicePayments";
import Spinner from "../common/Spinner";
import {
  AllPosted,
  ConErr,
  Found,
  Looking,
  NoneFound,
  PostingWorkbook,
  QuickBooks500,
  XerroNoErrors
} from "../conditionals/invoicePaymentsConditionals";
import ErrorItems from "./errors";
import InvoicePaymentItem from "./InvoicePaymentItem";

class InvoicePayments extends Component {
  state = {
    exported: false,
    postingToWorkbook: false,
    postedToWorkbook: false,
    showErrors: false,
    showButtons: true,
    showHeading: true,
    errors: [],
    errCount: 0,
    loading: false,
    xero500: false,
    modalShow: false
  };

  componentDidMount() {
    this.props.getQuickBooksInvoices();
  }

  handleShow = () => {
    this.setState({ modalShow: true });
  };

  // Event to post invoicePayments to WorkBook
  async onPostClickWorkbook(event, invoicePayments) {
    this.setState({ loading: true, postingToWorkbook: true });
    let res = await postWorkbookPayments(event, invoicePayments);
    console.log(res);

    let response = await res;
    this.setState({
      loading: false,
      showErrors: false,
      postingToWorkbook: false,
      postedToWorkbook: true
    });
    this.props.deleteQuickBooksInvoices();
    return response;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      this.props.invoicePayments === 500 ||
      nextProps.invoicePayments === 500
    ) {
      this.setState({ showButtons: false, xero500: true });
    }
    if (
      this.props.invoicePayments === "None" ||
      nextProps.invoicePayments === "None"
    ) {
      this.setState({ showButtons: false });
    }
  }

  render() {
    const { invoicePayments, loading } = this.props.invoicePayment;
    const {
      errors,
      showErrors,
      exported,
      showButtons,
      showHeading,
      xero500,
      errCount,
      postingToWorkbook,
      postedToWorkbook
    } = this.state;
    let InvoicePaymentItems;
    let errorItems;

    // HANDLE MODAL
    let modalClose = () => this.setState({ modalShow: false });

    // If invoicePayments are loading, push a spinner to the InvoicePaymentItems <div />
    if (invoicePayments === null || loading || this.state.loading) {
      InvoicePaymentItems = <Spinner animation="border" variant="primary" />;
    }

    //If WorkBook returns an error, push error response to the InvoicePaymentItems <div />
    else if (invoicePayments === 500) {
      InvoicePaymentItems = <ConErr />;
      //If WorkBook returns an error, push error response to the InvoicePaymentItems <div />
    } else if (postedToWorkbook === true) {
      InvoicePaymentItems = <AllPosted />;
    } else if (
      postedToWorkbook === false &&
      invoicePayments === "None" &&
      !loading
    ) {
      InvoicePaymentItems = <NoneFound />;
    }
    // If WorkBook returns invoicePayments, push invoice response to the invoiceItems <div />
    else if (invoicePayments !== undefined) {
      InvoicePaymentItems = (
        <TransitionGroup> {
          invoicePayments.map(invoicePayment => (
            <CSSTransition
              key={invoicePayment.Id}
              timeout={200}
              classNames="itemsAnimate"
            >
              <InvoicePaymentItem
                key={invoicePayment.Id}
                invoicePayment={invoicePayment}
              />
            </CSSTransition>
          ))
        }
        </TransitionGroup>
      )
      if (errors.length !== 0) {
        errorItems = errors.map(error => (
          <ErrorItems
            key={error.invoicePayment}
            show={this.state.modalShow}
            onHide={modalClose}
            errors={errors}
          />
        ));
      }
    }

    // WORKBOOK BUTTON CONDITIONALS
    let workbookButton;
    let buttonStyle;
    let buttonTitle;
    if (loading) {
      workbookButton = null;
    }
    if (!loading && errCount === 0) {
      buttonStyle = "btn btn-sm m-2 btn-success";
      buttonTitle = "";
    }
    if (!loading) {
      workbookButton = (
        <button
          style={{ float: "left" }}
          className={buttonStyle}
          onClick={e => this.onPostClickWorkbook(e, invoicePayments)}
          title={buttonTitle}
        >
          {" "}
          Update in WorkBook
        </button>
      );
    }

    // HEADING TEXT CONDITIONALS
    let headingText;
    if (loading && !exported) {
      headingText = <Looking />;
    }
    if (postingToWorkbook) {
      headingText = <PostingWorkbook />;
    }
    if (
      !exported &&
      !this.state.loading &&
      invoicePayments !== undefined &&
      invoicePayments !== "None" &&
      invoicePayments.length > 0
    ) {
      headingText = (
        <h4>
          <span style={{ color: "#4885ED" }}>{invoicePayments.length}</span>
          <Found />
        </h4>
      );
    }
    if (
      !postedToWorkbook &&
      !loading &&
      invoicePayments !== undefined &&
      invoicePayments.length === 0
    ) {
      headingText = <NoneFound />;
    }
    if (exported && errCount === 0) {
      headingText = <XerroNoErrors />;
    }
    if (!loading && xero500 === true) {
      headingText = <QuickBooks500 />;
    }

    return (
      <Container style={{ textAlign: "center" }}>
        {showHeading === true ? (
          <Row className="d-flex flex-column headingText">{headingText}</Row>
        ) : null}
        {InvoicePaymentItems.length !== 0 &&
          showButtons &&
          !xero500 &&
          !this.state.loading &&
          invoicePayments.length !== 0 ? (
          <Row className="justify-content-sm-left paymentButton">
            {workbookButton}
          </Row>
        ) : null}
        <Row className="d-flex flex-column radioContainer">
          <Col xs={6}></Col>
        </Row>
        {showErrors ? (
          <Row className="d-flex flex-column errorsContainer">{errorItems}</Row>
        ) : null}
        {!xero500 ? (
          <Row className="d-flex flex-column">
            {InvoicePaymentItems}
          </Row>
        ) : null}
      </Container>
    );
  }
}

InvoicePayments.propTypes = {
  deleteQuickBooksInvoices: PropTypes.func,
  getQuickBooksInvoices: PropTypes.func.isRequired,
  invoicePayment: PropTypes.object.isRequired,
  errors: PropTypes.object
};

const mapStateToProps = state => ({
  invoicePayment: state.invoicePayments,
  invoicePayments: state.invoicePayments.invoicePayments,
  errors: state.errors
});

const mapDispatchToProps = dispatch => {
  return {
    postInvoices: invoicePayments => dispatch(postInvoices(invoicePayments)),
    getQuickBooksInvoices: invoicePayments =>
      dispatch(getQuickBooksInvoices(invoicePayments)),
    deleteQuickBooksInvoices: invoicePayments =>
      dispatch(deleteQuickBooksInvoices(invoicePayments)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(InvoicePayments);
