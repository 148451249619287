import format from "date-fns/format";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Card, ListGroup } from "react-bootstrap";
import { connect } from "react-redux";
import styled from "styled-components";
import { deleteQuickBooksInvoice } from "../../actions/quickbooks/fetchQuickBooks/getInvoicePayments";

class InvoicePaymentItem extends Component {
  state = {
    isAuthorisedChecked: ""
  };

  onDeleteClick(e) {
    this.props.deleteQuickBooksInvoice(this.props.invoicePayment.Id);
  }

  render() {
    const { invoicePayment } = this.props;

    console.log(invoicePayment);

    const postDate = moment(invoicePayment.PostDate).format("DD/MM/YYYY");
    const paidDate = moment(invoicePayment.PaymentDate).format("DD/MM/YYYY");

    return (
      <Wrapper>
        <Card style={{ marginBottom: "2.5em" }} className="paymentCard">
          <Card.Header>
            <div
              style={{
                float: "left",
                fontWeight: "bold",
                fontSize: "1.2em"
              }}
            >
              Invoice# {invoicePayment.Number}
            </div>

            <div
              className="item-delete"
              style={{
                textAlign: "right",
                color: "red",
                cursor: "pointer",
                float: "right"
              }}
            >
              <i
                className="fa fa-times"
                aria-hidden="true"
                onClick={this.onDeleteClick.bind(this)}
              />
            </div>
          </Card.Header>

          <ListGroup variant="flush">
            <table className="table table-striped paymentTable">
              <thead>
                <tr style={{ textAlign: "left" }}>
                  <th style={{ width: "20%" }} scope="col">
                    Client
                  </th>
                  <th style={{ width: "20%" }} scope="col">
                    Headline
                  </th>
                  <th style={{ width: "20%" }} scope="col">
                    QuickBooks Paid Total
                  </th>
                  <th style={{ width: "20%" }} scope="col">
                    WB Issued
                  </th>
                  <th style={{ width: "20%" }} scope="col">
                    QuickBooks Paid Date
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr style={{ textAlign: "left" }}>
                  <td>{invoicePayment.contact}</td>
                  <td>{invoicePayment.Headline}</td>
                  <td>${invoicePayment.qbTotalPaid}</td>
                  <td>{postDate}</td>
                  <td>{paidDate}</td>
                </tr>
              </tbody>
            </table>
          </ListGroup>
        </Card>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  font-size: 12px;
  width: auto;
  margin-bottom: 1em;
`;

InvoicePaymentItem.propTypes = {
  invoicePayment: PropTypes.object.isRequired,
  deleteQuickBooksInvoice: PropTypes.func
};

const mapDispatchToProps = dispatch => {
  return {
    deleteQuickBooksInvoice: invoicePayment =>
      dispatch(deleteQuickBooksInvoice(invoicePayment))
  };
};

export default connect(mapDispatchToProps, { deleteQuickBooksInvoice })(
  InvoicePaymentItem
);
