import axios from "axios";
import { EXPORT_EXPENSES } from "../../redux/types";

const onPostClickQuickBooks = async function onPostClickQuickBooks(expenses) {
  try {
    // REBUID EXPENSES ARRAY TO MAP TO QUICKBOOKS BATCH REQUIREMENTS
    // https://developer.intuit.com/app/developer/qbo/docs/api/accounting/all-entities/batch
    let bId = 1;
    
    const expensesMapped = {
      BatchItemRequest: expenses.map((i) => {
        return {
          bId: "bid" + bId++,
          operation: "create",
          Purchase: {
            AccountRef: {
              name: i.AccountRef.AccountName, 
              value: i.AccountRef.AccountCode,
            },
            TxnDate: i.Date,
            DocNumber: i.InvoiceNumber,
            TotalAmt: i.Total,
            Line: i.LineItems.map((l) => {
              const taxCodeRef = l.TaxAmount !== 0
                ? { value: "TAX" }
                : null;

              let lineItem = {
                DetailType: "ItemBasedExpenseLineDetail",
                Amount: l.Amount + l.TaxAmount,
                ItemBasedExpenseLineDetail: {
                  ItemRef: {
                    name: l.ActivityName,
                  },
                },
                Description: l.Description,
              };

              if (taxCodeRef != null) {
                lineItem.ItemBasedExpenseLineDetail.TaxCodeRef = taxCodeRef;
              }

              return lineItem;
            }),
            EntityRef: {
              name: i.Contact.Name,
            },
            PaymentType: i.PayMethod,
            PrivateNote: i.Reference
          },
        };
      }),
    };
    // IF ACCESS TOKEN DOES NOT EXIST RETURN A 500 ERROR TO THE CLIENT THAT THEY WILL NEED TO CONNECT
    if (localStorage.getItem('access_token') === null || localStorage.getItem('responseURL') === null) {
      return 500;
    }
    
    // GET realmId (company ID) AND AUTH FROM LOCAL STORAGE
    const realmId = localStorage.responseURL
      .split("realmId=")
      .pop()
      .split('"')[0]
      .toString();
    const access_token = localStorage.access_token;
    const toPost = { expensesMapped, realmId, access_token };

    // Send expense data
    let res = await axios.post("/api/postFunctions/quickbooks", { action: EXPORT_EXPENSES, toPost: toPost });

    return res.data;
  } catch (e) {
    console.log(e);
    return 500;
  }
};

export default onPostClickQuickBooks;
