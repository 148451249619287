import React from 'react';

export default () => {
  return (
    <footer className="footer mt-5" style={{ padding: '0.5rem' }}>
      <p className="float-left ml-4">
        Copyright &copy; {new Date().getFullYear()}&nbsp; 
        <a href="https://www.smartsoftware.com.au" style={{ color: 'inherit', textDecoration: 'inherit'}}>
          SmartSoftware
        </a>
      </p>
      <p className="float-right mr-3">
        Version: {process.env.REACT_APP_VERSION ?? "DEV"}
      </p>
    </footer>
  );
};
