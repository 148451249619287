import axios from "axios";
import { GET_ACCOUNTS } from "../../redux/types";

export const getAccounts = async () => {
  try {

    if (localStorage.getItem('access_token') === null || localStorage.getItem('responseURL') === null) {
      return 500;
    }

    // GET realmId (company ID), expiry and refresh token
    const realmId = localStorage.responseURL
      .split("realmId=")
      .pop()
      .split('"')[0]
      .toString();
    const access_token = localStorage.access_token;
    const toPost = { realmId, access_token };

    // GET ACCOUNTS DATA 
    // https://developer.intuit.com/app/developer/qbo/docs/api/accounting/all-entities/account
    let res = await axios.post("/api/postFunctions/quickbooks", { action: GET_ACCOUNTS, toPost: toPost });
    return res;
  } catch (e) {
    console.log(e);
    return 500;
  }
};
