import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { createProfile, getCurrentProfile } from "../../actions/redux/profileActions";
import isEmpty from "../../validation/is-empty";
import TextFieldGroup from "../common/TextFieldGroup";
import Spinner from '../common/Spinner';

class CreateProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingProfile: true,
      displaySocialInputs: false,
      handle: "",
      company: "",
      workbookURL: "",
      workbookUserName: "",
      workbookPassword: "",
      companyID: "",
      errors: {}
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    this.props.getCurrentProfile();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }

    if (nextProps.profile.profile) {
      const profile = nextProps.profile.profile;

      // If profile field doesnt exist, make empty string
      profile.workbookURL = !isEmpty(profile.workbookURL)
        ? profile.workbookURL
        : "";
      profile.workbookUserName = !isEmpty(profile.workbookUserName)
        ? profile.workbookUserName
        : "";
      profile.workbookPassword = !isEmpty(profile.workbookPassword)
        ? profile.workbookPassword
        : "";
      profile.companyID = !isEmpty(profile.companyID) ? profile.companyID : "";

      // Set component fields state
      this.setState({
        handle: profile.handle,
        workbookURL: profile.workbookURL,
        workbookUserName: profile.workbookUserName,
        workbookPassword: profile.workbookPassword,
        companyID: profile.companyID,
        loadingProfile: false,
      });
    }
  }

  onSubmit(e) {
    e.preventDefault();

    const profileData = {
      handle: this.state.handle,
      workbookURL: this.state.workbookURL,
      workbookUserName: this.state.workbookUserName,
      workbookPassword: this.state.workbookPassword,
      companyID: this.state.companyID
    };

    this.props.createProfile(profileData, this.props.history);
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  render() {
    const { errors, loadingProfile } = this.state;
    const { loading } = this.props.profile;
    let editProfileContent;

    if (loading) {
      editProfileContent = (
        <div className="col-md-8 m-auto">
          <div className="centreDisplay">
            <h3>{loadingProfile ? "Loading" : "Validating"} settings...</h3>
            <Spinner />
          </div>
        </div>
      );
    } else {
      editProfileContent = (
        <div className="col-md-8 m-auto">
          <h1 className="display-4 text-center">Edit Settings</h1>
          <small className="d-block pb-3">* = required fields</small>
          <form onSubmit={this.onSubmit}>
            <TextFieldGroup
              placeholder="* Profile Handle"
              name="handle"
              value={this.state.handle}
              onChange={this.onChange}
              error={errors.handle}
              info="A unique handle for your profile URL. Your full name, company name, nickname"
            />
            <TextFieldGroup
              placeholder="* WorkBook URL"
              name="workbookURL"
              value={this.state.workbookURL}
              onChange={this.onChange}
              error={errors.workbookURL}
              info="Must put WorkBook URL"
            />
            <TextFieldGroup
              placeholder="* WorkBook User Name"
              name="workbookUserName"
              value={this.state.workbookUserName}
              onChange={this.onChange}
              error={errors.workbookUserName}
              info="Must put WorkBook User Name"
            />
            <TextFieldGroup
              placeholder="* WorkBook Password"
              name="workbookPassword"
              value={this.state.workbookPassword}
              onChange={this.onChange}
              error={errors.workbookPassword}
              info="Must put WorkBook Password"
              type="password"
            />
            <TextFieldGroup
              placeholder="Company ID"
              name="companyID"
              value={this.state.companyID}
              onChange={this.onChange}
              info="Only use this if you have multiple companies in WorkBook!"
            />
            {
              errors.noConnection && 
              <div 
                className="alert alert-danger"
                style={{ margin: "auto", width: "max-content" }}
                >{errors.noConnection}
              </div>
            }
            <div style={{ margin: "0.7em auto 4.2em auto", width: "max-content" }}>
              <Link
                to="/dashboard"
                className="btn btn-secondary btn-sm"
                style={{ marginRight: "3em" }}
              >
                Cancel
              </Link>
              <input
                type="submit"
                className="btn btn-primary btn-sm"
                value="Submit and Test WB Connection"
              />
            </div>
          </form>
        </div>
      );
    }

    return (
      <div className="create-profile">
        <div className="container">
          <div className="row">
            {editProfileContent}
          </div>
        </div>
      </div>
    );
  }
}

CreateProfile.propTypes = {
  createProfile: PropTypes.func.isRequired,
  getCurrentProfile: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  profile: state.profile,
  errors: state.errors
});

export default connect(mapStateToProps, { createProfile, getCurrentProfile })(
  withRouter(CreateProfile)
);
