import axios from "axios";
import { EXPORT_CREDITOR_INVOICES } from "../../redux/types";

const onPostClickQuickBooks = async function onPostClickQuickBooks(invoices) {
  try {
    // REBUID CREDITOR INVOICES TO MATCH REQUIRED BY QUICKBOOKS BATCH API IMPORT
    // https://developer.intuit.com/app/developer/qbo/docs/api/accounting/all-entities/batch
    let bId = 1;
    const creditorInvoicesMapped = {
      BatchItemRequest: invoices.map((i) => {
        return {
          bId: "bid" + bId++,
          operation: "create",
          Bill: {
            TxnDate: i.Date,
            DocNumber: i.InvoiceNumber,
            TotalAmt: i.Total,
            Line: i.LineItems.map((l) => {
              return {
                DetailType: "ItemBasedExpenseLineDetail",
                Amount: l.Amount + l.TaxAmount,
                ItemBasedExpenseLineDetail: {
                  TaxCodeRef: {
                    value: "TAX"
                  },
                  ItemRef: {
                    name: l.ActivityName,
                  },
                  UnitPrice: l.Amount,
                },
                Description: l.Description,
              };
            }),
            DueDate: i.DueDate,
            VendorRef: {
              name: i.Contact.Name,
            },
            PrivateNote: i.Reference
          },
        };
      }),
    };

    // IF NO LOCAL STORAGE ITEM WAS FOUND
    if (localStorage.getItem('access_token') === null || localStorage.getItem('responseURL') === null) {
      return 500;
    }

    // GET realmId (company ID) AND access_token FROM THE LOCAL STORAGE
    const realmId = localStorage.responseURL
      .split("realmId=")
      .pop()
      .split('"')[0]
      .toString();
    const access_token = localStorage.access_token;
    const toPost = { creditorInvoicesMapped, realmId, access_token };

    // Send invoice data
    let res = await axios.post("/api/postFunctions/quickbooks", { action: EXPORT_CREDITOR_INVOICES, toPost: toPost });

    return res.data;
  } catch (e) {
    console.log(e);
    return 500;
  }
};

export default onPostClickQuickBooks;
