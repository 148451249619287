import TextField from "@material-ui/core/TextField";
import axios from "axios";
import PropTypes from "prop-types";
import React, { Component, Fragment } from "react";
import { inputStyle, LinkButtons, SubmitButtons } from "./authComponents";

export default class ResetPassword extends Component {
  constructor() {
    super();

    this.state = {
      email: "",
      password: "",
      confirmPassword: "",
      updated: false,
      isLoading: true,
      error: false,
      errorMsg: "",
      resetToken: "",
    };
  }

  async componentDidMount() {
    const token = this.props.location.search.split("?").pop();

    await axios
      .get("/api/passwords/reset", {
        params: {
          resetPasswordToken: token
        }
      })
      .then(response => {
        console.log(response.data)
        if (response.data.message === "password reset link a-ok") {
          this.setState({
            email: response.data.email,
            updated: false,
            isLoading: false,
            error: false,
            resetToken: token
          });
        }
      })
      .catch(error => {
        this.setState({
          updated: false,
          isLoading: false,
          error: true
        });
      });
  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };

  updatePassword = e => {
    e.preventDefault();

    if (this.state.password.trim() === "" || this.state.password.length < 6){
      this.setState({
        isLoading: false,
        errorMsg: "Password cannot be empty and must be at least 6 characters",
        updated: false
      });
    } 
    else if (this.state.password !== this.state.confirmPassword){
      this.setState({
        isLoading: false,
        errorMsg: "Password and confirm password do not match",
        updated: false
      });
    } else {
      axios .put("/api/passwords/updatePasswordViaEmail", {
        email: this.state.email,
        password: this.state.password,
        resetPasswordToken: this.state.resetToken
      })
      .then(response => {
        if (response.data.message === "password updated") {
          this.setState({
            updated: true,
            error: false
          });
        } else {
          this.setState({
            updated: false,
            error: true
          });
        }
      })
      .catch(error => {
        console.log(error.response.data);
      });
    }
  };

  render() {
    const { 
      password,
      confirmPassword, 
      error,
      errorMsg, 
      isLoading, 
      updated 
    } = this.state;

    if (isLoading) {
      return (
        <div>
          <div style={{ marginTop: "5em", textAlign: "center" }}>
            Loading User Data...
          </div>
        </div>
      );
    }
    if (!isLoading) {
      return (
        <div style={{ marginTop: "5em", textAlign: "center" }}>
          { !updated && !error && (
            <Fragment>
              <h4>Enter a new password</h4>
              <form className="password-form" onSubmit={this.updatePassword}>
                <div className="form-group">
                  <TextField
                    style={inputStyle}
                    id="password"
                    label="Password"
                    onChange={this.handleChange("password")}
                    value={password}
                    type="password"
                  />
                </div>
                <div className="form-group">
                  <TextField
                    style={inputStyle}
                    id="confirmPassword"
                    label="Confirm Password"
                    onChange={this.handleChange("confirmPassword")}
                    value={confirmPassword}
                    type="password"
                  />
                </div>
                { errorMsg !== "" && <p className="errorStyle">Error: {errorMsg}.</p> }
                <SubmitButtons
                  buttonStyle={{
                    marginTop: "1.5em",
                    marginLeft: "1em",
                    backgroundColor: "#4885ED"
                  }}
                  buttonText="Update Password"
                />
              </form>
            </Fragment>
          )}
          { updated && !error && (
            <Fragment>
              <div>
                <p>
                  Your password has been successfully reset, please try logging
                  in again.
                </p>
                <LinkButtons
                  buttonStyle={{
                    marginTop: "1.5em",
                    marginLeft: "1em",
                    backgroundColor: "#4885ED"
                  }}
                  buttonText="Login"
                  link="/login"
                />
              </div>
            </Fragment>
          )}
          { !updated && error && (
            <Fragment>
              <h4>Problem resetting password. Please send another reset link.</h4>
              <LinkButtons
                buttonText="Go Home"
                buttonStyle={{
                  marginTop: "1.5em",
                  marginLeft: "1em",
                  backgroundColor: "#4885ED"
                }}
                link="/"
              />
              <LinkButtons
                buttonStyle={{
                  marginTop: "1.5em",
                  marginLeft: "1em",
                  backgroundColor: "#4885ED"
                }}
                buttonText="Forgot Password?"
                link="/forgotPassword"
              />
            </Fragment>
          )}
        </div>
      );
    }
  }
}

ResetPassword.propTypes = {
  // eslint-disable-next-line react/require-default-props
  match: PropTypes.shape({
    params: PropTypes.shape({
      token: PropTypes.string.isRequired
    })
  })
};
