import axios from "axios";
import React, { Component } from "react";
import { withRouter } from "react-router";

class Quickbooks extends Component {
  async componentDidMount() {
    let response = await axios.get("/api/connect");
    window.location.href = response.data;
  }
  render() {
    return <div />;
  }
}

export default withRouter(Quickbooks);
