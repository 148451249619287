import PropTypes from "prop-types";
import React, { Component } from "react";
import { TransitionGroup, CSSTransition, Transition } from 'react-transition-group';
import { Button, Col, Container, Row } from "react-bootstrap";
import { connect } from "react-redux";
import {
  deletePurchases,
  getPurchases,
  postPurchases,
  updatePayments,
} from "../../actions/redux/purchaseActions";
import postWorkbook from "../../actions/workbook/post/purchases";
import postQuickBooks from "../../actions/quickbooks/exportQuickBooks/purchasesActions";
import Spinner from "../common/Spinner";
import {
  AllPosted,
  ConErr,
  ExportingQuickBooks,
  FinalizedWorkbook,
  Found,
  IssuesFound,
  Looking,
  NoneFound,
  PostingWorkbook,
  QuickBooks500,
  XerroNoErrors,
} from "../conditionals/purchaseHeaderConditionals";
import QuickBooksButton from "./Button";
import ErrorItems from "./errors";
import PurchaseItem from "./PurchaseItem";

class Purchases extends Component {
  state = {
    exported: false,
    exportingToQuickBooks: false,
    postingToWorkbook: false,
    postedToWorkbook: false,
    showErrors: false,
    showButtons: true,
    showHeading: true,
    errors: [],
    errCount: 0,
    loading: false,
    modalShow: false,
    accountPayable: JSON.parse(localStorage.getItem("q_acc")) ?
      JSON.parse(localStorage.getItem("q_acc"))[0] :
      { acc_Id: "0", acc_AcctNum: "0", acc_Name: "None Found" },
  };

  componentDidMount() {
    this.props.getPurchases(this.state.accountPayable);
  }

  handleShow = () => {
    this.setState({ modalShow: true });
  };

  handleUpdatePayments = (event) => {
    const account = JSON.parse(event.target.value)
    this.props.updatePayments(account);
    this.setState({ accountPayable: account });
  };

  // Event to post purchases to WorkBook
  async onPostClickWorkbook(event, purchases) {
    this.setState({ loading: true, postingToWorkbook: true });
    let res = await postWorkbook(event, purchases);
    let response = await res;
    this.setState({
      loading: false,
      showErrors: false,
      exported: true,
      postingToWorkbook: false,
      postedToWorkbook: true,
    });
    this.props.deletePurchases();
    return response;
  }

  onPostClickQuickBooks = async () => {
    const purchases = this.props.purchases;
    this.setState({ loading: true, exportingToQuickBooks: true });
    let res = await postQuickBooks(purchases);
    let response = await res;
    this.setState({ loading: false, exportingToQuickBooks: false });
    if (response === 500) {
      this.setState({
        exported: false,
        xero500: true,
      });
      return;
    }
    if (response !== undefined || response == []) {
      this.setState({
        exported: true,
        xero500: false,
      });
    }
    if (response !== "none" || response !== 500) {
      this.setState({
        showErrors: true,
        errors: response.map((i) => {
          return {
            purchase: i.PurchaseOrderNumber,
            errors: i.Error,
          };
        }),
      });
      //let errCount = 0
      if (response !== "none") {
        let errCount = response.length;
        this.setState({
          errCount: errCount,
        });
        if (errCount > 0) {
          this.setState({ modalShow: true });
        }
      }
    }
    return response;
  };
  // Check for errors returned by QuickBooks.

  componentWillReceiveProps(nextProps) {
    if (this.props.purchase === 9 || nextProps.purchase === 9) {
      this.setState({ showButtons: false });
    }
  }

  render() {
    const { purchases, loading } = this.props.purchase;
    const {
      errors,
      showErrors,
      exported,
      showButtons,
      showHeading,
      xero500,
      errCount,
      postingToWorkbook,
      exportingToQuickBooks,
      postedToWorkbook,
      accountPayable
    } = this.state;
    let purchaseItems;
    let errorItems;

    // HANDLE MODAL
    let modalClose = () => this.setState({ modalShow: false });

    // If purchases are loading, push a spinner to the purchaseItems <div />
    if (purchases === null || loading || this.state.loading) {
      purchaseItems = <Spinner animation="border" variant="primary" />;
    }

    // If WorkBook returns an error, push error response to the purchaseItems <div />
    else if (purchases === 9) {
      purchaseItems = <ConErr />;
    }

    // If WorkBook returns purchases, push purchase response to the purchaseItems <div />
    else {
      if (purchases !== undefined) {
        purchaseItems = (
          <TransitionGroup> {
            purchases.map((purchase) => (
              <CSSTransition
                key={purchase.Id}
                timeout={200}
                classNames="itemsAnimate"
              >
                <PurchaseItem key={purchase.Id} purchase={purchase} />
              </CSSTransition>
            ))
          }
          </TransitionGroup>
        )
      } else {
        purchaseItems = <FinalizedWorkbook />;
      }

      if (errors.length !== 0) {
        errorItems = errors.map((error) => (
          <ErrorItems
            key={error.purchase}
            show={this.state.modalShow}
            onHide={modalClose}
            errors={errors}
          />
        ));
      }
      // else {
      //   errorItems = <AllPosted />;
      // }
    }

    // WORKBOOK BUTTON CONDITIONALS
    let workbookButton;
    let buttonStyle;
    let buttonTitle;
    if (loading) {
      workbookButton = null;
    }
    if (!loading && !exported) {
      buttonStyle = "btn btn-sm m-2 btn-danger";
      buttonTitle = "WARNING: Purchases not yet exported!";
    }
    if (!loading && exported && errCount !== 0) {
      buttonStyle = "btn btn-sm m-2 btn-outline-danger";
      buttonTitle = "WARNING: Purchases may have issues in QuickBooks!";
    }
    if (!loading && exported && errCount === 0) {
      buttonStyle = "btn btn-sm m-2 btn-success";
      buttonTitle = "";
    }
    if (!loading && !postedToWorkbook) {
      workbookButton = (
        <button
          style={{ float: "right" }}
          className={buttonStyle}
          onClick={(e) => this.onPostClickWorkbook(e, purchases)}
          title={buttonTitle}
        >
          {" "}
          Update in WorkBook
        </button>
      );
    }

    // QUICKBOOKS BUTTON CONDITIONALS
    let xeroButton;
    if (!loading && !exported) {
      xeroButton = (
        <QuickBooksButton
          buttonClass={`btn btn-sm m-2 btn-primary`}
          title={"Purchases Loaded, export to QuickBooks?"}
          onPostClickQuickBooks={this.onPostClickQuickBooks}
        />
      );
    }
    if (!loading && exported && showErrors && errCount !== 0) {
      xeroButton = (
        <Button variant="danger" onClick={this.handleShow}>
          Show Errors
        </Button>
      );
    }
    if (loading || (exported && !showErrors)) {
      xeroButton = null;
    }

    // HEADING TEXT CONDITIONALS
    let headingText;
    if (loading && !exported) {
      headingText = <Looking />;
    }
    if (exportingToQuickBooks) {
      headingText = <ExportingQuickBooks />;
    }
    if (postingToWorkbook) {
      headingText = <PostingWorkbook />;
    }
    if (
      !exported &&
      !this.state.loading &&
      purchases !== undefined &&
      purchases.length > 0
    ) {
      headingText = (
        <h4>
          <span style={{ color: "#4885ED" }}>{purchases.length}</span>
          <Found />
        </h4>
      );
    }
    if (
      !exported &&
      !loading &&
      purchases !== undefined &&
      purchases.length === 0
    ) {
      headingText = <NoneFound />;
    }
    if (exported && errCount === 0) {
      headingText = <XerroNoErrors />;
    }
    if (exported && errCount !== 0) {
      headingText = <IssuesFound />;
    }
    if (!loading && xero500 === true) {
      headingText = <QuickBooks500 />;
    }
    if (postedToWorkbook) {
      headingText = <AllPosted />;
    }

    // GET ACCOUNTS PAYABLE FROM LOCAL STORAGE. IF IT DOES NOT EXIST OR IS EMPTY, CREATE A BLANK ONE TO AVOID MAPPING ERRORS.
    let accountsPayableOptions = [accountPayable];
    if (
      localStorage.getItem("q_acc") !== undefined &&
      localStorage.getItem("q_acc") !== null &&
      localStorage.getItem("q_acc").length !== 0
    ) {
      accountsPayableOptions = JSON.parse(localStorage.getItem("q_acc"));
    }

    return (
      <Container>
        {showHeading === true ? (
          <Row className="d-flex flex-column headingText">{headingText}</Row>
        ) : null}
        {purchases.length !== 0 &&
          showButtons &&
          !xero500 &&
          !this.state.loading &&
          purchases !== 9 ? (
          <Row className="justify-content-sm-left paymentButton">
            <Col className="xeroButton">{xeroButton}</Col>
            <Col className="workbookButton">{workbookButton}</Col>
          </Row>
        ) : null}

        {purchases.length !== 0 &&
          !exported &&
          showButtons &&
          purchases !== 9 &&
          !xero500 &&
          !loading &&
          !this.state.loading ? (
          <Row className="d-flex flex-column radioContainer">
            <Col xs={6}>
              Payment Method:
              <select
                value={JSON.stringify(accountPayable)}
                onChange={this.handleUpdatePayments}
                className="form-control"
                id="paymentMethod"
              >
                {accountsPayableOptions.map((account) => {
                  return <option value={JSON.stringify(account)} key={account.acc_Id}>{account.acc_Name}</option>;
                })}
              </select>
            </Col>
          </Row>
        ) : null}

        {showErrors ? (
          <Row className="d-flex flex-column errorsContainer">{errorItems}</Row>
        ) : null}
        {!xero500 ? (
          <Row className="d-flex flex-column">
            {purchaseItems}
          </Row>
        ) : null}
      </Container>
    );
  }
}

Purchases.propTypes = {
  deletePurchases: PropTypes.func,
  updateStatus: PropTypes.func,
  getPurchases: PropTypes.func.isRequired,
  purchase: PropTypes.object.isRequired,
  errors: PropTypes.object,
  postPurchases: PropTypes.func,
  updatePayments: PropTypes.func,
};

const mapStateToProps = (state) => ({
  purchase: state.purchases,
  purchases: state.purchases.purchases,
  errors: state.errors,
});

const mapDispatchToProps = (dispatch) => {
  return {
    postPurchases: (purchases) => dispatch(postPurchases(purchases)),
    getPurchases: (accPayable) => dispatch(getPurchases(accPayable)),
    deletePurchases: (purchases) => dispatch(deletePurchases(purchases)),
    updatePayments: (accPayable) => dispatch(updatePayments(accPayable)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Purchases);
