import PropTypes from "prop-types";
import React, { Component } from "react";
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { Button, Col, Container, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { deleteInvoices, getInvoices, postInvoices } from "../../actions/redux/invoiceActions";
import postQuickBooks from "../../actions/quickbooks/exportQuickBooks/invoicesActions";
import postWorkbook from "../../actions/workbook/post/invoices";
import Spinner from "../common/Spinner";
import { AllPosted, ConErr, ExportingQuickBooks, FinalizedWorkbook, Found, IssuesFound, Looking, NoneFound, PostingWorkbook, QuickBooks500, XerroNoErrors } from "../conditionals/invoiceHeaderConditionals";
import QuickBooksButton from "./Button";
import ErrorItems from "./errors";
import InvoiceItem from "./InvoiceItem";

class Invoices extends Component {
  state = {
    exported: false,
    exportingToQuickBooks: false,
    postingToWorkbook: false,
    postedToWorkbook: false,
    showErrors: false,
    showButtons: true,
    showHeading: true,
    errors: [],
    errCount: 0,
    loading: false,
    xero500: false,
    modalShow: false
  };

  componentDidMount() {
    this.props.getInvoices();
  }


  handleShow = () => {
    this.setState({ modalShow: true });
  };

  // Event to post invoices to WorkBook
  async onPostClickWorkbook(event, invoices) {
    this.setState({ loading: true, postingToWorkbook: true });
    let res = await postWorkbook(event, invoices);
    let response = await res;
    this.setState({
      loading: false,
      showErrors: false,
      exported: true,
      postingToWorkbook: false,
      postedToWorkbook: true
    });
    this.props.deleteInvoices();
    return response;
  }

  onPostClickQuickBooks = async () => {
    const invoices = this.props.invoices;
    this.setState({ loading: true, exportingToQuickBooks: true });
    let res = await postQuickBooks(invoices);
    let response = await res;
    this.setState({ loading: false, exportingToQuickBooks: false });
    if (response === 500) {
      this.setState({
        exported: false,
        xero500: true
      });
      return;
    }
    if (response !== undefined || response == []) {
      this.setState({
        exported: true,
        xero500: false
      });
    }
    if (response !== "none" || response !== 500) {
      this.setState({
        showErrors: true,
        errors: response
          .map(i => {
            return {
              invoice: i.InvoiceNumber,
              errors: i.Error
            };
          }),
      });

      if (response !== "none") {
        let errCount = response.length;
        this.setState({
          errCount: errCount
        });
        if (errCount > 0) {
          this.setState({ modalShow: true });
        }
      }
    }
    return response;
  };

  // Check for errors returned by QuickBooks.

  componentWillReceiveProps(nextProps) {
    if (this.props.invoice === 9 || nextProps.invoice === 9) {
      this.setState({ showButtons: false });
    }
  }

  render() {
    const { invoices, loading } = this.props.invoice;
    const {
      errors,
      showErrors,
      exported,
      showButtons,
      showHeading,
      xero500,
      errCount,
      postingToWorkbook,
      exportingToQuickBooks,
      postedToWorkbook
    } = this.state;
    let invoiceItems;
    let errorItems;


    // HANDLE MODAL
    let modalClose = () => this.setState({ modalShow: false });

    // If invoices are loading, push a spinner to the invoiceItems <div />
    if (invoices === null || loading || this.state.loading) {
      invoiceItems = <Spinner animation="border" variant="primary" />;
    }

    // If WorkBook returns an error, push error response to the invoiceItems <div />
    else if (invoices === 9) {
      invoiceItems = <ConErr />;
    }

    // If WorkBook returns invoices, push invoice response to the invoiceItems <div />
    else {
      if (invoices !== undefined) {
        invoiceItems = (
          <TransitionGroup> {
            invoices.map(invoice => (
              <CSSTransition
                key={invoice.Id}
                timeout={200}
                classNames="itemsAnimate"
              >
                <InvoiceItem key={invoice.Id} invoice={invoice} />
              </CSSTransition>
            ))
          }
          </TransitionGroup>
        )
      } else {
        invoiceItems = <FinalizedWorkbook />;
      }

      if (errors.length !== 0) {
        errorItems = errors.map(error => (
          <ErrorItems
            key={error.invoice}
            show={this.state.modalShow}
            onHide={modalClose}
            errors={errors}
          />
        ));
      }
    }

    // WORKBOOK BUTTON CONDITIONALS
    let workbookButton;
    let buttonStyle;
    let buttonTitle;
    if (loading) {
      workbookButton = null;
    }
    if (!loading && !exported) {
      buttonStyle = "btn btn-sm m-2 btn-danger";
      buttonTitle = "WARNING: Invoices not yet exported!";
    }
    if (!loading && exported && errCount !== 0) {
      buttonStyle = "btn btn-sm m-2 btn-outline-danger";
      buttonTitle = "WARNING: Invoices may have issues in QuickBooks!";
    }
    if (!loading && exported && errCount === 0) {
      buttonStyle = "btn btn-sm m-2 btn-success";
      buttonTitle = "";
    }
    if (!loading && !postedToWorkbook && invoices.length > 0) {
      workbookButton = (
        <button
          style={{ float: "right" }}
          className={buttonStyle}
          onClick={e => this.onPostClickWorkbook(e, invoices)}
          title={buttonTitle}
        >
          {" "}
          Update in WorkBook
        </button>
      );
    }

    // QUICKBOOKS BUTTON CONDITIONALS
    let xeroButton;
    if (!loading && !exported) {
      xeroButton = (
        <QuickBooksButton
          buttonClass={`btn btn-sm m-2 btn-primary`}
          title={"Invoices Loaded, export to QuickBooks?"}
          onPostClickQuickBooks={this.onPostClickQuickBooks}
        />
      );
    }
    if (!loading && exported && showErrors && errCount !== 0) {
      xeroButton = (
        <Button variant="danger" onClick={this.handleShow}>
          Show Errors
        </Button>
      );
    }
    if (loading || (exported && !showErrors)) {
      xeroButton = null;
    }

    // HEADING TEXT CONDITIONALS
    let headingText;
    if (loading && !exported) {
      headingText = <Looking />;
    }
    if (exportingToQuickBooks) {
      headingText = <ExportingQuickBooks />;
    }
    if (postingToWorkbook) {
      headingText = <PostingWorkbook />;
    }
    if (
      !exported &&
      !this.state.loading &&
      invoices !== undefined &&
      invoices.length > 0
    ) {
      headingText = (
        <h4>
          <span style={{ color: "#4885ED" }}>{invoices.length}</span>
          <Found />
        </h4>
      );
    }
    if (
      !exported &&
      !loading &&
      invoices !== undefined &&
      invoices.length === 0
    ) {
      headingText = <NoneFound />;
    }
    if (exported && errCount === 0) {
      headingText = <XerroNoErrors />;
    }
    if (exported && errCount !== 0) {
      headingText = <IssuesFound />;
    }
    if (!loading && xero500 === true) {
      headingText = <QuickBooks500 />;
    }
    if (postedToWorkbook) {
      headingText = <AllPosted />;
    }

    return (
      <Container>
        {showHeading === true ? (
          <Row className="d-flex flex-column headingText">{headingText}</Row>
        ) : null}
        {invoices.length !== 0 &&
          showButtons &&
          !xero500 &&
          !this.state.loading &&
          invoices !== 9 ? (
          <Row className="justify-content-sm-left paymentButton">
            <Col className="xeroButton">{xeroButton}</Col>
            <Col className="workbookButton">{workbookButton}</Col>
          </Row>
        ) : null}

        {showErrors ? (
          <Row className="d-flex flex-column errorsContainer">{errorItems}</Row>
        ) : null}
        {!xero500 ? (
          <Row className="d-flex flex-column">
            {invoiceItems}
          </Row>
        ) : null}
      </Container>
    );
  }
}

Invoices.propTypes = {
  deleteInvoices: PropTypes.func,
  getInvoices: PropTypes.func.isRequired,
  invoice: PropTypes.object.isRequired,
  errors: PropTypes.object,
  postInvoices: PropTypes.func
};

const mapStateToProps = state => ({
  invoice: state.invoices,
  invoices: state.invoices.invoices,
  profile: state.profile,
  errors: state.errors
});

const mapDispatchToProps = dispatch => {
  return {
    postInvoices: invoices => dispatch(postInvoices(invoices)),
    getInvoices: invoices => dispatch(getInvoices(invoices)),
    deleteInvoices: invoices => dispatch(deleteInvoices(invoices)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Invoices);
