import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Button, Container, Modal, Table } from 'react-bootstrap';

class ErrorItems extends Component {
  constructor(props) {
    super(props);
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.state = {
      errors: [],
      modalShow: true
    };
  }
  handleClose() {
    this.setState({
      modalShow: false
    });
  }

  handleShow() {
    this.setState({ modalShow: true });
  }

  render() {
    const { errors } = this.props;

    return (
      <Modal
        {...this.props}
        aria-labelledby="contained-modal-title-vcenter"
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Issues Found ...
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            'maxHeight': 'calc(100vh - 210px)',
            'overflowY': 'auto'
          }}
        >
          <Container>
            <Table style={{ fontSize: '8pt' }} responsive="sm">
              <thead>
                <tr>
                  <th>Invoice</th>
                  <th>Errors</th>
                  <th style={{ paddingLeft: '50px' }}>Warnings</th>
                </tr>
              </thead>
              {errors.map(errors => {
                return (
                  <tbody>
                    <tr>
                      <td>{errors.invoice}</td>
                      {errors.errors !== 'No errors' ? (
                        <td>
                          {errors.errors.map(error => {
                            return <span>{error.Message}</span>;
                          })}
                        </td>
                      ) : (
                        <td> </td>
                      )}
                      {errors.warnings !== 'No errors' ? (
                        <td>
                          {errors.warnings.map(warning => {
                            return <ul>{warning.Message}</ul>;
                          })}
                        </td>
                      ) : (
                        <td> </td>
                      )}
                    </tr>
                  </tbody>
                );
              })}
            </Table>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this.props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

ErrorItems.propTypes = {
  errors: PropTypes.array
};

export default ErrorItems;
