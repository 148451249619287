import {
  GET_PURCHASES,
  DELETE_PURCHASE,
  DELETE_PURCHASES,
  PURCHASES_LOADING,
  POST_PURCHASES,
  UPDATE_PURCHASE_SINGLE_PAYMENT,
  UPDATE_PURCHASE_PAYMENTS
} from "../types";

const initialState = {
  purchases: [],
  loading: false
};

export default function (state = initialState, action) {
  switch (action.type) {
    case PURCHASES_LOADING:
      return {
        ...state,
        loading: true
      };
    case GET_PURCHASES:
      return {
        ...state,
        purchases: action.payload,
        loading: false
      };
    case DELETE_PURCHASE:
      return Object.assign({}, state, {
        purchases: [
          ...state.purchases.filter(purchase => purchase.Id !== action.payload)
        ]
      });
    case UPDATE_PURCHASE_SINGLE_PAYMENT:
      return {
        purchases: [
          ...state.purchases.map(item => {
            if (item.Id === action.payload) {
              item.AccountAP.AccountCode = action.acc_Payable.acc_Id;
              item.AccountAP.DisplayName = action.acc_Payable.acc_Name;
              item.AccountAP.AcctNum = action.acc_Payable.acc_AcctNum;
            }
            return item;
          })
        ]
      };
    case UPDATE_PURCHASE_PAYMENTS:
      return {
        purchases: [
          ...state.purchases.map(item => {
            if (item.Id !== action.payload) {
              item.AccountAP.AccountCode = action.acc_Payable.acc_Id;
              item.AccountAP.DisplayName = action.acc_Payable.acc_Name;
              item.AccountAP.AcctNum = action.acc_Payable.acc_AcctNum;
            }
            return item;
          })
        ]
      };
    case DELETE_PURCHASES:
      return { purchases: [] };
    case POST_PURCHASES:
      return {
        ...state,
        purchases: action.payload,
        loading: true
      };
    default:
      return state;
  }
}
