import axios from "axios";
import { EXPORT_INVOICES } from "../../redux/types";

const onPostClickQuickBooks = async function onPostClickQuickBooks(invoices) {
  const custPOField = JSON.parse(localStorage.getItem("q_custPO"))[0] ?
                      JSON.parse(localStorage.getItem("q_custPO"))[0] : null;
  
  try {
    // REBUID INVOICES INTO THE STRUCTURE NEEDED FOR A QUICKBOOKS BATCH REQUEST
    // https://developer.intuit.com/app/developer/qbo/docs/api/accounting/all-entities/batch
    let bId = 1;
    const invoicesMapped = {
      BatchItemRequest: invoices.map((i) => {
        const invoice = {
          bId: "bid" + bId++,
          operation: "create",
          Invoice: {
            TxnDate: i.Date,
            DocNumber: i.InvoiceNumber,
            //TotalAmt: i.Total,
            Line: i.LineItems.map((l) => {
              // QB doesn't allow custom net amounts. If it doesn't match use empty string for Qty before QB export 
              const totalMatch = Math.round((l.Quantity * l.Rate) * 100) / 100 == l.AmountNet;
              return {
                Description: l.Description,
                DetailType: "SalesItemLineDetail",
                SalesItemLineDetail: {
                  TaxCodeRef: {
                    value: "TAX"
                  },
                  //TaxInclusiveAmt: l.UnitAmount + l.TaxAmount, 
                  ItemRef: {
                    name: l.ActivityName,
                  },
                  Qty: totalMatch ? l.Quantity : "",
                  UnitPrice: l.Rate,
                },
                Amount: l.AmountNet,
              };
            }),
            DueDate: i.DueDate,
            CustomerRef: {
              name: i.Contact.Name,
              //value: i.Contact.ExternalCode,
            },
            BillEmail: {
              Address: i.Contact.Email
            },
            PrivateNote: i.Reference
          },
        };

        if (custPOField) {
          invoice.Invoice.CustomField = [
            {
              DefinitionId: custPOField.Id,
              StringValue: i.CustOrderNo,
              Type: "StringType",
              Name: custPOField.Value
            }
          ];
        }

        return invoice;
      }),
    };

    // IF ACCESS TOKEN DOES NOT EXIST RETURN A 500 ERROR TO THE CLIENT THAT THEY WILL NEED TO CONNECT
    if (localStorage.getItem('access_token') === null || localStorage.getItem('responseURL') === null) {
      return 500;
    }

    // GET realmId (company ID), AND access_token FROM LOCAL STORAGE
    const realmId = localStorage.responseURL
      .split("realmId=")
      .pop()
      .split('"')[0]
      .toString();
    const access_token = localStorage.access_token;
    const toPost = { invoicesMapped, realmId, access_token };

    // SEND INVOICE DATA TO
    let res = await axios.post("/api/postFunctions/quickbooks", { action: EXPORT_INVOICES, toPost: toPost });

    return res.data;

  } catch (e) {
    console.log(e);
    return 500;
  }
};

export default onPostClickQuickBooks;
